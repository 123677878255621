import React from 'react';
import shortid from 'shortid';

import LearnReactView from '../views/LearnReactView';
import ReactView from '../views/ReactView';
import JavaScriptView from '../views/JavaScriptView';
import NullView from '../views/NullView';
import TextView from './TextView';
import ImageView from './ImageView';
import EditText from './EditText';
import GridDashboard from './GridDashboard';
import LinearHorizontalLayout from './LinearHorizontalLayout';
import GridView from './GridView';
import LinearLayout from './LinearLayout';
import RelativeLayout from './RelativeLayout';
import CircleImageView from './CircleImageView';

import TextViewDesign from './TextViewDesign';





import {    useRef } from "react";

import "./LinearLayoutContext.css";










 
 

export default function LinearLayoutContext({ views } ) {
console.log("LinearLayoutContext views props: ", views)
 let list_field  =  [];

  const subredditElementList = views.map(message => {
     console.log("LinearLayoutContext views message: ", message)
    let subreddit = message.layout
    let context =    message.context;
     let layout_context = message.layout_context;
    context.list_field = list_field
    let widget = subreddit.widget
   
     let propsLinearLayout = {
         data_field_value: message?.message?.data?.layout_data_field,
         properties: subreddit.properties,
         views: subreddit.views,
         context: context ,
         layout_context: layout_context
        
        
      }  
             let propsChild = {
         data_field_value: message?.message?.data?.layout_data_field,
         action: subreddit.action,
         widget: subreddit.widget,
         properties: subreddit.properties,
         views: subreddit.views,
         context: context ,
          layout_context: layout_context
        
      } 
                        //       inputFieldsFirebase: inputFieldsFirebase,
                        // setInputFieldsFirebase: setInputFieldsFirebase
    switch (widget) {
      case 'reactjs':
        return <ReactView key={shortid.generate()} />;
      case 'learnreactjs':
        return <LearnReactView key={shortid.generate()} />;
      case 'javascript':
        return <JavaScriptView key={shortid.generate()} />;
         
     case 'android.widget.TextView':
        return <TextView   propsChild={propsChild} />;
    case 'TextView':
      return <TextView   propsChild={propsChild} />;
        
        
         case 'android.widget.EditText':
        return <EditText   propsChild={propsChild} />;
    case 'EditText':
        return <EditText   propsChild={propsChild} />;

         case 'CircleImageView':
         return <CircleImageView   props={propsChild} />;

      case 'de.hdodenhof.circleimageview.CircleImageView':
      return <CircleImageView   props={propsChild} />;
       
      case "android.widget.ImageView":
      return <ImageView   props={propsChild } />;
      
      case 'ImageView':
      return <ImageView   props={propsChild } />;
      
      
      
    //   case 'android.widget.LinearLayout':
    //     console.log("LinearLayoutContext views context android.widget.LinearLayout: ", context)
       
    //   return <LinearLayout   views={subreddit.views}   context={context} />;
    // case 'LinearLayout':
    //     console.log("LinearLayoutContext views context LinearLayout: ", context)
        
    //   return <LinearLayout   views={subreddit.views}  context={context} />;
       
      case 'android.widget.LinearLayout':
         
 return (
           <div className="content-element-list">
                 
                <LinearLayout   props={propsLinearLayout}   />
            </div>
         );

     case 'LinearLayout':
       
       
        
      return <LinearLayout   props={propsLinearLayout}  />;
    
      
      case 'NestedScrollView':
      return <LinearLayout   props={propsLinearLayout}  />;
      
      case 'androidx.core.widget.NestedScrollView':
      return <LinearLayout   props={propsLinearLayout} />;
      

      
      case 'androidx.cardview.widget.CardView':
      return <LinearLayout   props={propsLinearLayout} />;
            
      case 'CardView':
      return <LinearLayout  props={propsLinearLayout} />;
      
      
      case 'android.widget.RelativeLayout':
      return <RelativeLayout  props={propsLinearLayout}/>;
            
      case 'RelativeLayout':
      return <RelativeLayout   props={propsLinearLayout} />;    
      
      
      case 'RecyclerView':
      return <RelativeLayout   props={propsLinearLayout} />;   
      
      case 'androidx.recyclerview.widget.RecyclerView':
      return <RelativeLayout   props={propsLinearLayout} />;   

      case 'androidx.appcompat.widget.Toolbar':
      return <LinearLayout   props={propsLinearLayout} />;
            
      case 'Toolbar':
      return <LinearLayout   props={propsLinearLayout} />; 
      
      
       case 'android.widget.HorizontalScrollView':
      return <LinearLayout   props={propsLinearLayout} />; 
      case 'android.widget.LinearHorizontalLayout':
      return <LinearLayout   props={propsLinearLayout} />; 
            
      case 'LinearHorizontalLayout':
      return <LinearLayout   props={propsLinearLayout} />; 

      case 'com.google.android.material.appbar.AppBarLayout':
      return <LinearLayout   props={propsLinearLayout}  />;
            
      case 'AppBarLayout':
      return <LinearLayout   props={propsLinearLayout} />;   
      
      case 'GridDashboard':
     return <LinearLayout   props={propsLinearLayout} />; 
       case 'android.widget.GridDashboard':
       return <LinearLayout   props={propsLinearLayout} />; 

     case 'GridView':
      let propsGridView0 = {
         metadata: subreddit,
         context: context  
        
      }  
        return (
            <div>

                <GridView   props={propsGridView0}      />
            </div>
         );
       case 'android.widget.GridView':
          let propsGridView1 = {
         metadata: subreddit,
         context: context  
        
      }  
      return (
       <div>

            <GridView   props={propsGridView1}    />
       </div>
      );
      
      
      
      
 

       
      default:
        return (
          <NullView
            key={shortid.generate()}
          > {`Dang phat trien:  widget `  +  widget } </NullView>
        );
    }
  });

 // return <div className="container">{subredditElementList}</div>;



let style_css_db = {




        "background-color":  '#00BFFF',

        'border': '10px solid #257C9E',
        'padding': '30px',

        'margin-bottom': '30px',



        // ...
}
    let style_css = {

  "width":  '1064px',

  "height":  '50px',
  "background-color":  '#00BFFF'

  // ...
}
    return (
                  <div class="inner-wrap"  >

                     <div className="container"          >{subredditElementList}</div>
                     </div>

    );

}
