 



 import "./RadioGroup.css";
import React from 'react';
import shortid from 'shortid';

import LearnReactView from '../views/LearnReactView';
import ReactView from '../views/ReactView';
import JavaScriptView from '../views/JavaScriptView';
import NullView from '../views/NullView';
import TextView from './TextView';
import ImageView from './ImageView';
import EditText from './EditText';
import GridDashboard from './GridDashboard';
import LinearHorizontalLayout from './LinearHorizontalLayout';
import GridView from './GridView';

import RadioButton from './RadioButton';








 
 
 
  // const properties =   [
  //   {
  //     "layout_width": "300dp"
  //   },
  //   {
  //     "background": "@drawable/bg_white_selector"
  //   },
  //   {
  //     "id": "@+id/txtDescription"
  //   },
  //   {
  //     "layout_height": "45dp"
  //   },
  //   {
  //     "textSize": "16sp"
  //   },
  //   {
  //     "text": "Các chức năng ứng dụng trắc nghiệm quiz3"
  //   },
  //   {
  //     "ellipsize": "end"
  //   },
  //   {
  //     "maxLines": 2
  //   },
  //   {
  //     "textColor": "@color/browser_actions_text_color"
  //   },
  //   {
  //     "layout_height:": "30dp"
  //   }
  // ];








//   return (
//     <div>
//       <div>
//           <ul className="gridDashboard" >
//             {list_item.map((job) => {
//               return (
//                 <li className="jobViewsBlock" key={job.layout_form} onClick={() => shoot(job.layout_form)}>
                
//                   <span className="jobTitle">{job.description}</span>
//                   <ImageView properties={job}  style={{ width: '40px',height:  '40px'  }} />   
//                 </li>
//               );
//             })}
//           </ul>
//         </div>
//     </div>
//   );




// <div class="flex-container">
//   <div class="flex-item-left">1</div>
//   <div class="flex-item-center">3</div>
//   <div class="flex-item-right">2</div>
 
// </div>

export default function RadioGroup({ props }   ) {
  let views =  props.views
  let context =    props.context
  
  
   
 //   return (
//     <div>
//       <div>
//           <ul className="gridDashboard" >
//             {  
   
  const subredditElementList = views.map(subreddit => {
    let widget = subreddit.widget
      let propsLinearLayout = {
         views: subreddit.views,
         context: context  
        
      } 
      
       let propsChild = {
         action: subreddit.action,
         widget: subreddit.widget,
         properties: subreddit.properties,
         views: subreddit.views,
         context: context  
        
      } 
    switch (widget) {
      
      
 
    
      
      case 'RadioButton':
 
      return <RadioButton   props={propsChild}      />;   
       case 'android.widget.RadioButton':
          
      return <RadioButton   props={propsChild}    />;
      
      
      
      
 

       
      default:
        return (
          <NullView
            key={shortid.generate()}
          >{`"r/${subreddit}" - not implemented`}</NullView>
        );
    }
  });
 
  return (
   
       
       <div    className="mainnavradioParent">
 
        <div className="mainnavradio"  >{subredditElementList}</div>;
                

    
       </div>
  
)
  
}
