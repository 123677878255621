

import "./CreateAppliation.css";
import React, { useEffect, useState, getInitialState } from "react";
import {    useRef } from "react";
 import { getDatabase, ref, onValue , child, push, update,set } from "firebase/database";

import {  onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";

 
import { useAuthState } from "react-firebase-hooks/auth";

 
import {db, auth,  signInWithGoogle, event_dashboard, load_toolbar,getCurrentTime } from "../cfgfirebase";


import Layout from '../views/Layout';



 
  let style_css = {

  "margin-left":  '80px' 
  // ...
}
 let uid =''
 
  function handleSubmit(e) {

    // Prevent the browser from reloading the page
    e.preventDefault();

    // Read the form data
    const form = e.target;
    const formData = new FormData(form);

 

    // Or you can work with it as a plain object:
    const formJson = Object.fromEntries(formData.entries());
    formJson.user_id = uid
        formJson.event = 'event_create_application_' + getCurrentTime()
        formJson.application = 'development'
    console.log(formJson);
   const  post_data =    {
              "application": "resshare_development",
              "dataCollection":  "../create_application",
              "jsonmap": formJson,
              "user_id" :  uid
              }
     console.log(post_data);
     
                                     
                           
    
            
            
            
            
                  const postsRef= ref(db, 'request')
        // Get a key for a new Post.
        const newPostKey = push(child(ref(db), 'request')).key;
        const starCountRef = ref(db, 'request/' + newPostKey  );
        let bInssert = false
        if(!bInssert)
        {
         // alert(bInssert) 
            set(starCountRef, post_data)
            .then(() => {
            //    alert("Data saved successfully!") 
              // Data saved successfully!
            })
            .catch((error) => {
              // The write failed...
            });
          
            bInssert = true
            document.getElementById("name").value = '';
            document.getElementById("logo").value = '';
            document.getElementById("key_seach").value = '';
            document.getElementById("description").value = '';
            document.getElementById("register_developer_key").value = '';

                    const commentsRef = ref(db, "response/" + uid+  '/' + formJson.event );



                  onChildAdded(commentsRef, (data) => {
                       let message =   data.val().data
                      alert(message)
                  });
                    }
     
  }


const CreateAppliation = () => {
           const [user, loading, error] = useAuthState(auth);
  
    useEffect(() => {
      
    if (loading) {
      // maybe trigger a loading screen
       return;
    }
    if (!user) 
    {
       
    }
    else
    {
           uid = user.uid;
    }
    
    
  }, [user, loading]);
 

  
  
//https://www.sanwebe.com/2014/08/css-html-forms-designs
  
  

  

  return (
            <Layout>
            <div class="form-style-10">
            <h1>Create Application Now!<span>Create application and build apps for your business</span></h1>
             <form method="post" onSubmit={handleSubmit}>
                <div class="section"><span>1</span>Application</div>
                <div class="inner-wrap">
                    <label>Application name <input type="text" name="name"  id="name" /></label>
                    <label>Logo URL <input type="text" name="logo" id="logo" /></label>
                    <label>Key seach <input type="text" name="key_seach"   id="key_seach" /></label>
                    <label>Description <textarea name="description"  id="description" ></textarea></label>
                </div>
            
                <div class="section"><span>2</span>Register developer key</div>
                <div class="inner-wrap">
                    <label>Register developer key <input type="text" name="register_developer_key" id="register_developer_key" /></label>
                </div>
            
                
                <div class="button-section">
                 <input type="submit"    value="Create" />
                </div>
            </form>
            </div>
            </Layout>

  );
}
export default CreateAppliation;