 
 import { Outlet, Link } from "react-router-dom";
import LinearLayout from '../components/LinearLayout';

import Layout from '../views/Layout';

import Searchbar from '../components/Searchbar';

 
import Application from "../pages/Application";
import Design from "../pages/Design";
import Blogs from "../pages/Blogs";
import Contact from "../pages/Contact";
import NoPage from "../pages/NoPage";
import Policy from "../pages/Policy";
import DeleteAccount from "../pages/DeleteAccount";


import LeftMenuAntd from '../components/LeftMenuAntd';
import Right from '../components/Right';


import React, { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import {db, auth,  signInWithGoogle, event_dashboard, load_toolbar } from "../cfgfirebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
 
import { getDatabase, ref, child, push, update,set } from "firebase/database";



import {   onValue   } from "firebase/database";

import {  onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";









import "./Navigation.css";
 
 import { onAuthStateChanged } from "../cfgfirebase";





export default function Navigation() {
    
const uid = onAuthStateChanged
const [user, loading, error] = useAuthState(auth);
  return (
        <div class="topnavNavigation">
  

         
         
          <Link to="/java">Java</Link>
          <Link to="/python">Python</Link>
          <Link to="/nodejs">NodeJS</Link>
          <Link to="/csharp">CSharp</Link>
          <Link to="/awslambdaserverless">AWS Lambda Serverless</Link>
          <Link to="/googlecloudfunctions">Google Cloud Functions</Link>
          <Link to="/microsoftazurefunctions">Microsoft Azure Functions</Link>
          <Link to="/kubernetes">Kubernetes    </Link>
          <Link to="/">Application</Link>
          <Link to="/design">Design</Link>
          
          <Link to="/blogs">Blogs</Link>
          <Link to="/policy">Policy</Link>
           <Link to="/deleteaccount">DeleteAccount</Link>


          <Link to="/contact">Contact</Link>
           
 
          <a href="#about" class="split"> { user?.displayName}</a>
         
               
      
          
          
        </div>
        
  );
}



 