// pages/About.js

import React from "react";
import "../styles.css";
 import "../App.css";

const Policy = () => (
  <div>
    <h1 className="title is-1">Chính sách quyền riêng tư</h1>
      <ul>

        <div>
         <h2 class="label new">Dữ liệu cá nhân và nhạy cảm của người dùng</h2>
  </div>
 <div  >
         <li>

        Dữ liệu riêng tư và nhạy cảm của người dùng bao gồm thông tin nhận dạng cá nhân, thông tin xác thực, thông tin liên hệ, thông tin vị trí của thiết bị, micrô, máy ảnh. Chúng tôi chỉ được truy cập, thu thập, sử dụng và chia sẻ dữ liệu riêng tư và nhạy cảm của người dùng có được qua ứng dụng cho những mục đích liên quan trực tiếp đến việc cung cấp và cải thiện tính năng của ứng dụng. Xử lý an toàn tất cả dữ liệu riêng tư và nhạy cảm của người dùng, bao gồm cả hoạt động truyền dữ liệu đó bằng phương thức mã hóa hiện đại (qua HTTPS).Yêu cầu quyền khi bắt đầu chạy, trước khi truy cập dữ liệu do các quyền của Android kiểm soát. Chúng tôi cam kết không bán dữ liệu riêng tư và nhạy cảm của người dùng.

        </li>
 </div>
      </ul>

  </div>
);

export default Policy;
