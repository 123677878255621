 
import { Outlet, Link } from "react-router-dom";
 

import "./CreateAppliation.css";

import "../styles.css";
 import "../App.css";
import Layout from '../views/Layout';
import Navigation from '../components/Navigation';
import Searchbar from '../components/Searchbar';

 
 
 
import Contact from "../pages/Contact";
import NoPage from "../pages/NoPage";
 import LinearLayout from '../components/LinearLayout';
 import LinearLayoutContext from '../components/LinearLayoutContext';
 
 
  import {grid_main_data} from '../pages/LayoutMain';

 
 import { useNavigate } from 'react-router-dom';


import { getDatabase, ref, onValue , child, push, update,set } from "firebase/database";

import {  onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";



 
import {signOut, db, auth,  signInWithGoogle, event_dashboard, load_toolbar } from "../cfgfirebase";

import React, { useEffect, useState, getInitialState } from "react";
import {    useRef } from "react";
 
import { useAuthState } from "react-firebase-hooks/auth";
import ReactDOM from "react-dom/client";
let  user_id = ""

    function shoot( ) {

 console.log("handleSubmit" )

        const   json =
              {
                   "user_id" : user_id
              }
                 const myJSON = JSON.stringify(json);
                const   post_data =  {
                                "application": "resshare_request",
                                "dataCollection": "../delete_account",
                                "json": myJSON,
                                "key": "key1",
                                "module": "core",
                                "os": "IOS",
                                "user_id": user_id
                            }





                  const postsRef= ref(db, 'request')
        // Get a key for a new Post.
        const newPostKey = push(child(ref(db), 'request')).key;
        const starCountRef = ref(db, 'request/' + newPostKey  );
             console.log(newPostKey );
               console.log(post_data );

        {
            set(starCountRef, post_data)
            .then(() => {
            })
            .catch((error) => {
            });

        }


 {
 alert("Delete account  successfully!");
        signOut(auth).then(() => {
        // Sign-out successful.
            navigate("/");
            console.log("Signed out successfully")
        }).catch((error) => {
        // An error happened.
        });
    }

    }

const DeleteAccount = () => {
  window.design_mode = false;
  let navigate = useNavigate();

  const [user, loading, error] = useAuthState(auth);
  const [grid_main_dashboard, setGrid_main_dashboard] = useState([]);
   const grid_main_dashboard1 =  []
    const count = useRef(0);
   const [gridMainDashboard1Count, setGridMainDashboard1Count] = useState([]);
   const [test, setTest] = useState(false);
   const layouts = useRef([]);
    const application_key = useRef( new Map());

   let  props = {



       }
  //const [inputValue, setInputValue] = useState("");


  useEffect(() => {
    setTest(!test)
    console.log(test);
  }, []);

  useEffect(() => {

    if (loading) {
      // maybe trigger a loading screen
       return;
    }
    if (!user)
    {
        navigate("/login");
    }
    else
    {
       user_id  = user.uid;




    }

  }, [user, loading]);
  let style_css = {

  "height":  '180px'
  // ...
}
   return (
                      <div class="button-section">
                            <div>
                                <label>Khi bạn bấm xóa tài khoản toàn bộ dữ liệu của bạn sẽ bị xóa khỏi hệ thống.  </label>
                            </div>
                            <div>
                                <label>When you click delete account all your data will be deleted from the system.  </label>
                            </div>
                             <div class="button-section"  style_css= {style_css} >

                                <input type="submit"    value="Delete Account (Xóa tài khoản) "    onClick={() => shoot( )}     />
                            </div>
                      </div>
     )
};

export default DeleteAccount;










 