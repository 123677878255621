import React from "react";



import "../styles.css";
 
import LinearLayout from '../components/LinearLayout';
 
import Layout from '../views/Layout';
 
// const views = [
//   'reactjs',
//   'TextView',
//   'learnreactjs',
//   'pics',
//   'reactjs',
//   'learnreactjs',
//   'svelte',
//   'javascript',
//   'learnreactjs'
// ];
const layout  = {
  "properties": [
    {
      "layout_width": "match_parent"
    },
    {
      "background": "@color/white"
    },
    {
      "layout_height": "718dp"
    },
    {
      "orientation": "vertical"
    }
  ],
  "views": [
    {
      "properties": [
        {
          "layout_width": "match_parent"
        },
        {
          "background": "@drawable/bg_button_subapp"
        },
        {
          "id": "@+id/app_bar_layout"
        },
        {
          "layout_height": "45dp"
        },
        {
          "theme": "@style/AppTheme.AppBarOverlay"
        }
      ],
      "views": [
        {
          "properties": [
            {
              "layout_width": "match_parent"
            },
            {
              "id": "@+id/toolbar"
            },
            {
              "layout_height": "match_parent"
            },
            {
              "popupTheme": "@style/AppTheme.PopupOverlay"
            }
          ],
          "views": [
            {
              "properties": [
                {
                  "layout_width": "match_parent"
                },
                {
                  "id": "@+id/relative_toool_bar"
                },
                {
                  "layout_height": "match_parent"
                },
                {
                  "orientation": "horizontal"
                }
              ],
              "views": [
                {
                  "properties": [
                    {
                      "layout_width": "46dp"
                    },
                    {
                      "id": "@+id/btn_back"
                    },
                    {
                      "layout_height": "46dp"
                    },
                    {
                      "scaleType": "centerInside"
                    },
                    {
                      "src": "https://firebasestorage.googleapis.com/v0/b/resshare-request-cb5e2.appspot.com/o/icon%2Ficons8-left-100.png?alt=media&token=056bdc13-4138-43b8-852e-9f612712e35a"
                    },
                    {
                      "layout_alignParentLeft": true
                    }
                  ],
                  "widget": "android.widget.ImageView"
                },
                {
                  "properties": [
                    {
                      "layout_width": "wrap_content"
                    },
                    {
                      "id": "@+id/tv_title"
                    },
                    {
                      "layout_height": "wrap_content"
                    },
                    {
                      "text": "Thêm sách "
                    },
                    {
                      "textSize": "16sp"
                    },
                    {
                      "layout_toRightOf": "@+id/btn_back"
                    },
                    {
                      "layout_gravity": "center_horizontal"
                    },
                    {
                      "layout_marginTop": "16dp"
                    },
                    {
                      "textColor": "@color/white"
                    }
                  ],
                  "widget": "android.widget.TextView"
                },
                {
                  "properties": [
                    {
                      "layout_width": "55dp"
                    },
                    {
                      "id": "@+id/btn_right"
                    },
                    {
                      "layout_height": "match_parent"
                    },
                    {
                      "scaleType": "centerInside"
                    },
                    {
                      "text": "Từ chối"
                    },
                    {
                      "layout_alignParentRight": true
                    },
                    {
                      "src": "https://firebasestorage.googleapis.com/v0/b/resshare-request-cb5e2.appspot.com/o/icon%2Ficons8-left-100.png?alt=media&token=056bdc13-4138-43b8-852e-9f612712e35a"
                    },
                    {
                      "textColor": "@color/white"
                    }
                  ],
                  "widget": "android.widget.ImageView"
                }
              ],
              "widget": "android.widget.LinearHorizontalLayout"
            }
          ],
          "widget": "androidx.appcompat.widget.Toolbar"
        }
      ],
      "widget": "com.google.android.material.appbar.AppBarLayout"
    },
    {
      "properties": [
        {
          "background": "#102027"
        },
        {
          "layout_width": "match_parent"
        },
        {
          "layout_height": "718dp"
        },
        {
          "padding": "5dp"
        }
      ],
      "views": [
        {
          "properties": [
            {
              "layout_width": "match_parent"
            },
            {
              "background": "@drawable/bg_gray_border"
            },
            {
              "paddingLeft": "10dp"
            },
            {
              "layout_height": "718dp"
            },
            {
              "paddingRight": "10dp"
            },
            {
              "paddingBottom": "5dp"
            },
            {
              "orientation": "vertical"
            },
            {
              "paddingTop": "10dp"
            }
          ],
          "views": [
            {
              "properties": [
                {
                  "layout_width": "400dp"
                },
                {
                  "id": "@+id/relative_image"
                },
                {
                  "layout_height": "180dp"
                },
                {
                  "paddingBottom": "0dp"
                },
                {
                  "paddingLeft": "90dp"
                },
                {
                  "paddingTop": "0dp"
                },
                {
                  "lignment": "center"
                }
              ],
              "views": [
                {
                  "properties": [
                    {
                      "id": "@+id/CardView"
                    },
                    {
                      "layout_width": "400dp"
                    },
                    {
                      "layout_height": "180dp"
                    },
                    {
                      "layout_gravity": "center"
                    },
                    {
                      "cardBackgroundColor": "@color/gray_light"
                    },
                    {
                      "alignment": "center"
                    }
                  ],
                  "views": [
                    {
                      "properties": [
                        {
                          "id": "@+id/imageViewScreen"
                        },
                        {
                          "layout_width": "400dp"
                        },
                        {
                          "layout_height": "180dp"
                        },
                        {
                          "field_name": "image"
                        },
                        {
                          "background": "https://firebasestorage.googleapis.com/v0/b/book-e0228.appspot.com/o/addbook%2FUntitled%20Diagram.drawio%20(1).png?alt=media&token=8a23fd78-ceac-4cc2-952d-c84aced43761"
                        },
                        {
                          "alignment": "center"
                        }
                      ],
                      "widget": "android.widget.ImageView"
                    }
                  ],
                  "widget": "androidx.cardview.widget.CardView"
                }
              ],
              "widget": "android.widget.LinearLayout"
            },
            {
              "properties": [
                {
                  "layout_width": "match_parent"
                },
                {
                  "id": "@+id/lbBookName"
                },
                {
                  "layout_height": "40dp"
                },
                {
                  "text": "Tên sách"
                },
                {
                  "textSize": "14sp"
                },
                {
                  "layout_marginTop": "0dp"
                },
                {
                  "textColor": "@color/browser_actions_text_color"
                }
              ],
              "widget": "android.widget.TextView"
            },
            {
              "properties": [
                {
                  "layout_width": "match_parent"
                },
                {
                  "background": "@drawable/bg_white_selector"
                },
                {
                  "id": "@+id/txtBookName"
                },
                {
                  "hint": "Click để nhập tên sách"
                },
                {
                  "field_name": "name"
                },
                {
                  "required": "Tên sách không để trống"
                },
                {
                  "layout_height": "40dp"
                },
                {
                  "textSize": "14sp"
                },
                {
                  "ellipsize": "end"
                },
                {
                  "singleLine": true
                },
                {
                  "maxLines": 1
                },
                {
                  "textColor": "@color/browser_actions_text_color"
                }
              ],
              "widget": "android.widget.EditText"
            },
            {
              "properties": [
                {
                  "layout_width": "match_parent"
                },
                {
                  "id": "@+id/lbAuthor"
                },
                {
                  "layout_height": "40dp"
                },
                {
                  "text": "Tác giả"
                },
                {
                  "textSize": "14sp"
                },
                {
                  "layout_marginTop": "0dp"
                },
                {
                  "textColor": "@color/browser_actions_text_color"
                }
              ],
              "widget": "android.widget.TextView"
            },
            {
              "properties": [
                {
                  "layout_width": "match_parent"
                },
                {
                  "background": "@drawable/bg_white_selector"
                },
                {
                  "id": "@+id/txtAuthor"
                },
                {
                  "field_name": "author_name"
                },
                {
                  "layout_height": "40dp"
                },
                {
                  "textSize": "14sp"
                },
                {
                  "ellipsize": "end"
                },
                {
                  "hint": "Click để chọn tác giả"
                },
                {
                  "singleLine": true
                },
                {
                  "maxLines": 1
                },
                {
                  "textColor": "@color/browser_actions_text_color"
                }
              ],
              "widget": "android.widget.EditText"
            },
            {
              "properties": [
                {
                  "layout_width": "match_parent"
                },
                {
                  "id": "@+id/lbCategory"
                },
                {
                  "layout_height": "40dp"
                },
                {
                  "text": "Thể loại"
                },
                {
                  "textSize": "14sp"
                },
                {
                  "layout_marginTop": "0dp"
                },
                {
                  "textColor": "@color/browser_actions_text_color"
                }
              ],
              "widget": "android.widget.TextView"
            },
            {
              "properties": [
                {
                  "layout_width": "match_parent"
                },
                {
                  "background": "@drawable/bg_white_selector"
                },
                {
                  "field_name": "category_name"
                },
                {
                  "id": "@+id/txtCategoryName"
                },
                {
                  "hint": "Click để chọn thể loại"
                },
                {
                  "layout_height": "40dp"
                },
                {
                  "textSize": "14sp"
                },
                {
                  "ellipsize": "end"
                },
                {
                  "singleLine": true
                },
                {
                  "maxLines": 1
                },
                {
                  "textColor": "@color/browser_actions_text_color"
                }
              ],
              "widget": "android.widget.EditText"
            },
            {
              "properties": [
                {
                  "layout_width": "match_parent"
                },
                {
                  "id": "@+id/lbPlotOverview"
                },
                {
                  "layout_height": "40dp"
                },
                {
                  "text": "Lời giới thiệu"
                },
                {
                  "textSize": "14sp"
                },
                {
                  "layout_marginTop": "0dp"
                },
                {
                  "textColor": "@color/browser_actions_text_color"
                }
              ],
              "widget": "android.widget.TextView"
            },
            {
              "properties": [
                {
                  "layout_width": "match_parent"
                },
                {
                  "background": "@drawable/bg_white_selector"
                },
                {
                  "field_name": "plot_overview"
                },
                {
                  "id": "@+id/txtPlotOverview"
                },
                {
                  "layout_height": "40dp"
                },
                {
                  "textSize": "14sp"
                },
                {
                  "ellipsize": "end"
                },
                {
                  "hint": "Click để nhập lời giới thiệu sách"
                },
                {
                  "minLines": 3
                },
                {
                  "textColor": "@color/browser_actions_text_color"
                }
              ],
              "widget": "android.widget.EditText"
            },
            {
              "properties": [
                {
                  "layout_width": "match_parent"
                },
                {
                  "id": "@+id/lbQuantity"
                },
                {
                  "layout_height": "40dp"
                },
                {
                  "text": "Số lượng"
                },
                {
                  "textSize": "14sp"
                },
                {
                  "layout_marginTop": "0dp"
                },
                {
                  "textColor": "@color/browser_actions_text_color"
                }
              ],
              "widget": "android.widget.TextView"
            },
            {
              "properties": [
                {
                  "layout_width": "match_parent"
                },
                {
                  "background": "@drawable/bg_white_selector"
                },
                {
                  "field_name": "quantity"
                },
                {
                  "id": "@+id/txtQuantity"
                },
                {
                  "hint": "Click để nhập số lượng"
                },
                {
                  "layout_height": "40dp"
                },
                {
                  "textSize": "14sp"
                },
                {
                  "ellipsize": "end"
                },
                {
                  "singleLine": true
                },
                {
                  "maxLines": 1
                },
                {
                  "textColor": "@color/browser_actions_text_color"
                },
                {
                  "text": "1"
                }
              ],
              "widget": "android.widget.EditText"
            },
            {
              "properties": [
                {
                  "layout_width": "match_parent"
                },
                {
                  "id": "@+id/lbCoverPrice"
                },
                {
                  "layout_height": "40dp"
                },
                {
                  "text": "Giá bìa"
                },
                {
                  "textSize": "14sp"
                },
                {
                  "layout_marginTop": "0dp"
                },
                {
                  "textColor": "@color/browser_actions_text_color"
                }
              ],
              "widget": "android.widget.TextView"
            },
            {
              "properties": [
                {
                  "layout_width": "match_parent"
                },
                {
                  "background": "@drawable/bg_white_selector"
                },
                {
                  "field_name": "cover_price"
                },
                {
                  "id": "@+id/txtCoverPrice"
                },
                {
                  "layout_height": "40dp"
                },
                {
                  "hint": "Click để nhập giá bìa"
                },
                {
                  "textSize": "14sp"
                },
                {
                  "ellipsize": "end"
                },
                {
                  "singleLine": true
                },
                {
                  "maxLines": 1
                },
                {
                  "textColor": "@color/browser_actions_text_color"
                },
                {
                  "text": "0"
                }
              ],
              "widget": "android.widget.EditText"
            },
            {
              "properties": [
                {
                  "layout_width": "match_parent"
                },
                {
                  "id": "@+id/lbPurchasePrice"
                },
                {
                  "layout_height": "40dp"
                },
                {
                  "text": "Giá mua vào"
                },
                {
                  "textSize": "14sp"
                },
                {
                  "layout_marginTop": "0dp"
                },
                {
                  "textColor": "@color/browser_actions_text_color"
                }
              ],
              "widget": "android.widget.TextView"
            },
            {
              "properties": [
                {
                  "layout_width": "match_parent"
                },
                {
                  "background": "@drawable/bg_white_selector"
                },
                {
                  "field_name": "purchase_price"
                },
                {
                  "id": "@+id/txtPurchasePrice"
                },
                {
                  "layout_height": "40dp"
                },
                {
                  "hint": "Click để nhập giá mua vào"
                },
                {
                  "textSize": "14sp"
                },
                {
                  "ellipsize": "end"
                },
                {
                  "singleLine": true
                },
                {
                  "maxLines": 1
                },
                {
                  "textColor": "@color/browser_actions_text_color"
                },
                {
                  "text": "0"
                }
              ],
              "widget": "android.widget.EditText"
            },
            {
              "properties": [
                {
                  "id": "group"
                },
                {
                  "layout_width": "match_parent"
                },
                {
                  "layout_height": "40dp"
                },
                {
                  "background": "#102027"
                },
                {
                  "text": "click"
                },
                {
                  "padding": "0dp"
                },
                {
                  "textSize": "16sp"
                },
                {
                  "padding": "0dp"
                },
                {
                  "gravity": "center"
                },
                {
                  "textColor": "#1f91f3"
                },
                {
                  "orientation": "horizontal"
                }
              ],
              "views": [
                {
                  "properties": [
                    {
                      "id": "adCTA1"
                    },
                    {
                      "layout_width": "wrap_content"
                    },
                    {
                      "layout_height": "wrap_content"
                    },
                    {
                      "background": "#102027"
                    },
                    {
                      "text": "Cho mượn"
                    },
                    {
                      "padding": "10dp"
                    },
                    {
                      "textSize": "16sp"
                    },
                    {
                      "padding": "10dp"
                    },
                    {
                      "textColor": "#6b6b6b"
                    },
                    {
                      "checked": true
                    },
                    {
                      "field_name": "free_loan"
                    },
                    {
                      "fieldType": "Boolean"
                    }
                  ],
                  "widget": "android.widget.RadioButton"
                },
                {
                  "properties": [
                    {
                      "id": "adCTA2"
                    },
                    {
                      "layout_width": "wrap_content"
                    },
                    {
                      "layout_height": "wrap_content"
                    },
                    {
                      "background": "#102027"
                    },
                    {
                      "text": "Bán"
                    },
                    {
                      "checked": false
                    },
                    {
                      "padding": "10dp"
                    },
                    {
                      "textSize": "16sp"
                    },
                    {
                      "padding": "10dp"
                    },
                    {
                      "textColor": "#6b6b6b"
                    }
                  ],
                  "widget": "android.widget.RadioButton"
                }
              ],
              "widget": "android.widget.RadioGroup"
            },
            {
              "properties": [
                {
                  "layout_width": "match_parent"
                },
                {
                  "id": "@+id/lbSalePrice"
                },
                {
                  "layout_height": "40dp"
                },
                {
                  "text": "Giá bán"
                },
                {
                  "textSize": "14sp"
                },
                {
                  "layout_marginTop": "0dp"
                },
                {
                  "textColor": "@color/browser_actions_text_color"
                }
              ],
              "widget": "android.widget.TextView"
            },
            {
              "properties": [
                {
                  "layout_width": "match_parent"
                },
                {
                  "background": "@drawable/bg_white_selector"
                },
                {
                  "field_name": "sale_price"
                },
                {
                  "id": "@+id/txtSalePrice"
                },
                {
                  "layout_height": "40dp"
                },
                {
                  "hint": "Click để nhập giá bán"
                },
                {
                  "textSize": "14sp"
                },
                {
                  "ellipsize": "end"
                },
                {
                  "singleLine": true
                },
                {
                  "maxLines": 1
                },
                {
                  "textColor": "@color/browser_actions_text_color"
                },
                {
                  "text": "0"
                }
              ],
              "widget": "android.widget.EditText"
            },
            {
              "properties": [
                {
                  "layout_width": "match_parent"
                },
                {
                  "id": "@+id/lbNumdaysBorrow"
                },
                {
                  "layout_height": "40dp"
                },
                {
                  "text": "Số ngày mượn"
                },
                {
                  "textSize": "14sp"
                },
                {
                  "layout_marginTop": "0dp"
                },
                {
                  "textColor": "@color/browser_actions_text_color"
                }
              ],
              "widget": "android.widget.TextView"
            },
            {
              "properties": [
                {
                  "layout_width": "match_parent"
                },
                {
                  "background": "@drawable/bg_white_selector"
                },
                {
                  "field_name": "numdays_borrow"
                },
                {
                  "id": "@+id/txtNumdaysBorrow"
                },
                {
                  "hint": "Click để nhập số ngày mượn"
                },
                {
                  "layout_height": "40dp"
                },
                {
                  "textSize": "14sp"
                },
                {
                  "ellipsize": "end"
                },
                {
                  "singleLine": true
                },
                {
                  "maxLines": 1
                },
                {
                  "textColor": "@color/browser_actions_text_color"
                },
                {
                  "text": "30"
                }
              ],
              "widget": "android.widget.EditText"
            }
          ],
          "widget": "android.widget.LinearLayout"
        }
      ],
      "widget": "androidx.core.widget.NestedScrollView"
    }
  ],
  "widget": "android.widget.LinearLayout"
} ;
// <App subredditsToShow={subredditsToShow} />


 
const Book = () => {
return (
        <Layout>
          <div>
            <div><LinearLayout views={layout.views} /></div>
        </div>
      </Layout>
);
};

export default Book;



