import "./GridView.css";
import TextView from './TextView';
import ImageView from './ImageView';
// import { redirect, useHistory } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from "react";

import { useAuthState } from "react-firebase-hooks/auth";

import { getDatabase, ref, onValue , child, push, update,set } from "firebase/database";

import {  onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";



 
import {db, auth,  signInWithGoogle, event_dashboard, load_toolbar } from "../cfgfirebase";









const jobViews = [
  {
    id: 1,
    job: "Full-Stack Developer",
    views: 150,
    url: 'book',
  },
  {
    id: 2,
    job: "  nodejs Developer",
    views: 25,
    url: 'nodejs',
  },
  {
    id: 3,
    job: "JavaScript Developer",
    views: 69,
    url: 'java',
  },
  {
    id: 4,
    job: "Python Developer",
    views: 45,
    url: 'python',
  },
    {
    id: 5,
    job: "java Developer",
    views: 55,
    url: 'java',
  }
];

 
const properties = [
  {
    "layout_width": "match_parent"
  },
  {
    "id": "@+id/lbBookName"
  },
  {
    "layout_height": "40dp"
  },
  {
    "text": "Tên sách"
  },
  {
    "textSize": "14sp"
  },
  {
    "layout_marginTop": "0dp"
  },
  {
    "textColor": "@color/browser_actions_text_color"
  }
];
const list_item = {}
//
//const list_item = {
//  "list_item": [
//    {
//      "description": "Giới thiệu ứng dụng",
//      "flow_chart": "",
//      "icon": "https://firebasestorage.googleapis.com/v0/b/covid19-b58d6.appspot.com/o/icon%2Fgrid_icon%2Fabout48.png?alt=media&token=b2007d15-75f8-4c98-878d-62e63979eec4",
//      "layout_form": "../configuration/system_setting/layout/android/form/introduction"
//    },
//    {
//      "description": "Tìm kiếm",
//      "flow_chart": "",
//      "fragment": "com.resshare.core.screen.DynamicFragmentRecyclerView",
//      "icon": "https://firebasestorage.googleapis.com/v0/b/book-e0228.appspot.com/o/icon_main%2Ficons8-search-48.png?alt=media&token=3e1c8522-5eb4-4432-9b7f-529ad2733553",
//      "layout_form": "../configuration/system_setting/layout/android/form/search"
//    },
//    {
//      "description": "Thêm sách",
//      "flow_chart": "",
//      "icon": "https://firebasestorage.googleapis.com/v0/b/book-e0228.appspot.com/o/icon_main%2Ficons8-add-book-48.png?alt=media&token=95e59da8-6ed8-4b89-9340-8ddbb99a45c0",
//      "layout_form": "../configuration/system_setting/layout/android/form/add_book"
//    },
//    {
//      "description": "Thông tin liên hệ",
//      "flow_chart": "",
//      "fragment": "com.resshare.core.screen.LocationDynamicActivity",
//      "icon": "https://firebasestorage.googleapis.com/v0/b/book-e0228.appspot.com/o/icon_main%2Ficons8-contact-50.png?alt=media&token=1080ac13-4f91-4d79-8562-cfe1e9e1f872",
//      "layout_form": "../configuration/system_setting/layout/android/form/toicobinhoxy"
//    },
//    {
//      "description": "Tủ sách của bạn",
//      "flow_chart": "",
//      "fragment": "com.resshare.core.screen.DynamicFragmentRecyclerView",
//      "icon": "https://firebasestorage.googleapis.com/v0/b/book-e0228.appspot.com/o/icon_main%2Ficons8-bookcase-64.png?alt=media&token=b52cc930-9f43-4a9c-9c55-b66292a182b2",
//      "layout_form": "../configuration/system_setting/layout/android/form/my_bookcase"
//    }
//  ]
//};


function listenerDashboard()
{
  
 
}

 


export default function GridView(  {props}       ) {
  //metadata {properties} ,   { views } 
  
    let metadata =  props.metadata
  let context =    props.context
  
  console.log("GridView views data: ", metadata)
  
    console.log("GridView views context: ", context)
  let list_item = []
  let properties = metadata.properties
  let properties3 = metadata.properties[3]
  if(properties3)
  {
   let  grid_view_data = properties3.grid_view_data
    if(grid_view_data)
    {
      list_item = grid_view_data.list_item
    }
  }
  
 
  
   
  let navigate = useNavigate();
  
  const shoot = (layout_form) => {
    
            
        let newString = layout_form.replaceAll("/", "%2F") + "____" + context.application + "____"+ context.app_user_id ;
    
       //   alert("generalform")
    navigate('generalform/'+ newString);
  };
  

  return (
    <div>
       <div>
          <ul className="gridDashboard" >
            {list_item.map((job) => {
              return (
                <li className="jobViewsBlock" key={job.layout_form} onClick={() => shoot(job.layout_form)}>
                
                  <span className="jobTitle">{job.description}</span>
                  <ImageView props={job}  style={{ width: '40px',height:  '40px'  }} />   
                </li>
              );
            })}
          </ul>
        </div>
    </div>
  );
}






 