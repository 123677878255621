import React, { useState } from "react";
import "./Bookcase.css";
import ImageView from '../components/ImageView';
const books= [{
    "author_name": "Tác giả: B",
    "book_id": "BK-NvLW2RuuKGmJ6XLpHQv",
    "book_items_id": "IT-NvMsN2aWArmsRdp5uu1",
    "category_name": "Loại: C",
    "cover_price": "Giá bìa: 0",
    "free_loan": "Sách cho mượn",
    "icon": "https://firebasestorage.googleapis.com/v0/b/resshare-request-cb5e2.appspot.com/o/book%2F6BB23D04-F045-4579-B402-FE90E3AA3802?alt=media&token=c7625406-a128-49d0-94df-eb9ef09ea7ec",
    "name": "Tên sách: A",
    "quantity": "Số lượng: 1",
    "state_flow": "",
    "state_flow_title": "Xóa",
    "value_price": "Số ngày mượn: 30",
    "views_count": "Lượt xem: 2"
  },
  {
    "author_name": "Tác giả: B",
    "book_id": "BK-NvLW2RuuKGmJ6XLpHQv",
    "book_items_id": "IT-NvMsN2aWArmsRdp5uu1",
    "category_name": "Loại: C",
    "cover_price": "Giá bìa: 0",
    "free_loan": "Sách cho mượn",
    "icon": "https://firebasestorage.googleapis.com/v0/b/resshare-request-cb5e2.appspot.com/o/book%2F6BB23D04-F045-4579-B402-FE90E3AA3802?alt=media&token=c7625406-a128-49d0-94df-eb9ef09ea7ec",
    "name": "Tên sách: A",
    "quantity": "Số lượng: 1",
    "state_flow": "",
    "state_flow_title": "Xóa",
    "value_price": "Số ngày mượn: 30",
    "views_count": "Lượt xem: 2"
  },{
    "author_name": "Tác giả: B",
    "book_id": "BK-NvLW2RuuKGmJ6XLpHQv",
    "book_items_id": "IT-NvMsN2aWArmsRdp5uu1",
    "category_name": "Loại: C",
    "cover_price": "Giá bìa: 0",
    "free_loan": "Sách cho mượn",
    "icon": "https://firebasestorage.googleapis.com/v0/b/resshare-request-cb5e2.appspot.com/o/book%2F6BB23D04-F045-4579-B402-FE90E3AA3802?alt=media&token=c7625406-a128-49d0-94df-eb9ef09ea7ec",
    "name": "Tên sách: A",
    "quantity": "Số lượng: 1",
    "state_flow": "",
    "state_flow_title": "Xóa",
    "value_price": "Số ngày mượn: 30",
    "views_count": "Lượt xem: 2"
  }];

  const categories = [
    {"name":"Sách của bạn"},
    {"name":"Sách cho mượn"},
    {"name":"Sách đi mượn"},
    {"name":"Sách mua"},

  ]
const Bookcase = () => {
    const [selected, setSelected] = useState('');
    return (
        <div className="bookcase">
            <div className="page-heading">
                <span className="heading-text"><svg onClick={()=> history.back()} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                    </svg>Tủ sách của bạn
                </span>
            </div>
            <div className="page-content">
                <div className="categories-bookcase">
                {categories.map(cate => {
                    return (<div onClick={() => { setSelected(cate.name); alert("chọn " + cate.name); }} className={`category-item ${cate.name === selected ? 'selected' : ''}`}>
                        {cate.name}
                    </div>)
                })}
                </div>
            
            {books.map(book => {
                return (
                    <div className="book-item">
                        <div className="book-item-image"><ImageView props={book}  style={{ width: '150px'  }} /></div>
                        <div className="book-item-content">
                            <ul>
                                <li>{book.name}</li>
                                <li>{book.category_name}</li>
                                <li>{book.author_name}</li>
                                <li>{book.cover_price}</li>
                                <li>{book.quantity}</li>
                                <li>{book.free_loan}</li>
                                <li>{book.value_price}</li>
                                <li>{book.views_count}</li>
                                <li><button onClick={()=> alert("Xóa thành công!")} className="button-remove">Xóa</button></li>
                            </ul>
                        </div>
                    </div>
                )
            })}
            </div>
        </div>
    );
  };
  
  export default Bookcase;