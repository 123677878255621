import React from 'react';
import shortid from 'shortid';

import LearnReactView from '../views/LearnReactView';
import ReactView from '../views/ReactView';
import JavaScriptView from '../views/JavaScriptView';
import NullView from '../views/NullView';
import TextView from './TextView';
import ImageView from './ImageView';
import EditText from './EditText';
import AppEditTextV1 from './AppEditTextV1';
import GridDashboard from './GridDashboard';
import LinearHorizontalLayout from './LinearHorizontalLayout';
import RelativeLayout from './RelativeLayout';


import GridView from './GridView';



import RadioGroup from './RadioGroup';




 import RecyclerView from './RecyclerView';
 
 import CircleImageView from './CircleImageView'; 
 
       import TextViewDesign from './TextViewDesign';
 

let labelTxt = 'Name (4 to 8 characters)'
let id =  'id'

    function isEmptyObject(value) {
    
         
    if (value  === 'null') { return true;}
     if (value  === 'undefined') { return true;}
       if (value  === undefined) { return true;}
        if (value  === null) { return true;}
         if (value  === '') { return true;}
    
   
    
  return Object.keys(value).length === 0 && value.constructor === Object;
}
 

let  textarea =''
  function  applyproperties(arr_properties) {
 textarea =''
 let sentence =  isEmptyObject(arr_properties)
  if (sentence  === false) { 
  
    let fLen = arr_properties.length;

 
for (let i = 0; i < fLen; i++) {
    if(arr_properties[i] != undefined)
    {
   let key = Object.keys(arr_properties[i])[0]
    
      let value = Object.values(arr_properties[i])[0]
      
       switch (key)
       {
        case 'id':
          id  = value;
          break
        case 'text':
          labelTxt  = value;
          break
        case 'textarea':
          textarea  = value;
          break
          case 'style':
          style_css_db  = value;
          break      
        default  :
        
          break
       }
    // console.log( value) ; 
 
}
}
   
  }
  }
  
    let style_css_db = ''
    let style_css = {

  "width":  '1064px',
   
  "height":  '50px',
  "background-color":  '#00BFFF'

  // ...
}


export default function LinearLayoutDesign({ props }   ) {
    
      style_css_db = ''
  applyproperties(props.properties)
  if(  style_css_db === '' )
   {
       
   }
    
    
    
  let views =  props.views
  let context =    props.context
  let  layout_context =  props.layout_context
  
  
   console.log("LinearLayout views context: ", context)
  const subredditElementList = views.map(subreddit => {
    let widget = subreddit.widget
      let propsLinearLayout = {
         properties: subreddit.properties,
         views: subreddit.views,
         context: context,
         layout_context:layout_context,
          data_field_value: props.data_field_value
        
      }  
       let propsChild = {
         action: subreddit.action,
         widget: subreddit.widget,
         properties: subreddit.properties,
         views: subreddit.views,
         context: context ,
         layout_context:layout_context,
          data_field_value: props.data_field_value
        
      }



    switch (widget) {
        
        
        
        
        
      case 'de.hdodenhof.circleimageview.CircleImageView':

      
        
   

      
      case 'reactjs':
        return <ReactView key={shortid.generate()} />;
      case 'learnreactjs':
        return <LearnReactView key={shortid.generate()} />;
      case 'javascript':
        return <JavaScriptView key={shortid.generate()} />;
         
     case 'android.widget.TextView':

    case 'TextView':

        
        
         case 'android.widget.EditText':

    case 'EditText':

        
        
                 case 'android.widget.SearchView':

    case 'SearchView':

        
        
        
                         case 'android.widget.Spinner':

    case 'Spinner':

        


            
       
      case "android.widget.ImageView":

      
      case 'ImageView':

      
      
      
      
            case "android.widget.RadioGroup":

      
      case 'RadioGroup':
  return <TextViewDesign   propsChild={propsChild} />
      

      
            case 'RecyclerView':


            case 'androidx.recyclerview.widget.RecyclerView':

      
      
      
      
      case 'android.widget.LinearLayout':
   
       

     case 'LinearLayout':
       
      

    
      
      case 'NestedScrollView':

      case 'androidx.core.widget.NestedScrollView':

      

      
      case 'androidx.cardview.widget.CardView':

            
      case 'CardView':

      
      
      case 'android.widget.RelativeLayout':

            
      case 'RelativeLayout':

           
      case 'androidx.appcompat.widget.Toolbar':

            
      case 'Toolbar':

      
      
      case 'android.widget.LinearHorizontalLayout':

            
      case 'LinearHorizontalLayout':

      
       case 'HorizontalScrollView':

 
      
      
       case 'android.widget.HorizontalScrollView':

      
      
      case 'com.google.android.material.appbar.AppBarLayout':

            
      case 'AppBarLayout':

      
      case 'GridDashboard':

       case 'android.widget.GridDashboard':

      
      
      
      case 'GridView':

       case 'android.widget.GridView':


     return (
            <div>

                    <div>
                        <TextViewDesign   propsChild={propsChild} />
                    </div>
                    <div>
                         <LinearLayoutDesign   props={propsLinearLayout}   />
                    </div>
               </div>
           );


      
      
      
      
 

       
      default:
        return (
          <NullView
            key={shortid.generate()}
          >{`Dang phat trien: ` + widget}</NullView>
        );
    }




  });
  

        return <div    > {subredditElementList}       </div>

}
