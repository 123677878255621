import React, { useEffect, useState } from "react";

import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LayoutMain  from "./pages/LayoutMain";
import Application from "./pages/Application";
import Design from "./pages/Design";
import Blogs from "./pages/Blogs";
import GeneralForm from "./pages/GeneralForm";

import Policy from "./pages/Policy";
import DeleteAccount from "./pages/DeleteAccount";

import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import Java from "./pages/Java";
import Python from "./pages/Python";
import CSharp  from "./pages/CSharp";
import NodeJS from "./pages/NodeJS";
import AWSLambdaServerless from "./pages/AWSLambdaServerless";
import MicrosoftAzureFunctions from "./pages/MicrosoftAzureFunctions";
import GoogleCloudFunctions from "./pages/GoogleCloudFunctions";
import DynamicPage from "./pages/DynamicPage";
import Book from "./pages/Book";
import CreateAppliation from "./pages/CreateAppliation";
import RegisterDeveloper from "./pages/RegisterDeveloper";
import ReactCarousel from "./pages/ReactCarousel";
import Kubernetes from "./pages/Kubernetes";
import JsonEditor from "./pages/JsonEditor";
import Bookcase from "./pages/Bookcase";




import Login from "./Login";
import Register from "./Register";
import Reset from "./Reset";

import {  useNavigate } from "react-router-dom";
import {db, auth,  signInWithGoogle, event_dashboard, load_toolbar } from "./cfgfirebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
 
import { getDatabase, ref, child, push, update,set } from "firebase/database";

export default function App() {
  const [user, loading, error] = useAuthState(auth);
  
  const navigate = useNavigate();
   var  load_dashboard = true;
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
       return;
    }
    if (!user) 
    {
        navigate("/login");
    }
    else
    {
    if(load_dashboard==false)
        {
        console.log("load dashboard")
        load_dashboard =true
        const uid = user.uid;
        const  request_grid_main = {
          application: "resshare",
          dataCollection: "draft/core/get_home_layout",
          json: "{\"reference\":\"system_setting/layout/web/home\",\"user_id\":\"" +   uid        + "\",\"channel\":\"web"    + "\",\"event_dashboard\":\"" +   event_dashboard  + "\",\"event\":\""+  load_toolbar + "\"}",
           
          key: "key1",
          module: "core",
          channel: "web",
         
          user_id: uid
        }
       const postsRef= ref(db, 'request')
        // Get a key for a new Post.
        const newPostKey = push(child(ref(db), 'request')).key;
        const starCountRef = ref(db, 'request/' + newPostKey  );
        
 
  
            set(starCountRef, request_grid_main)
            .then(() => {
              // Data saved successfully!
            })
            .catch((error) => {
              // The write failed...
            });
    }
    }
    
    
  }, [user, loading]);
  
  return (
    <>
      <Routes>
        
        <Route path="/" element={<LayoutMain  />}>
        <Route index element={<Application  />} />
        <Route path="application" element={<Application />} />
        <Route path="blogs" element={<Blogs />} />
        <Route path="policy" element={<Policy />} />
         <Route path="deleteaccount" element={<DeleteAccount />} />


        <Route path="generalform/:id" element={<GeneralForm />} />
        
        <Route path="design/generalform/:id" element={<GeneralForm />} />
        
        <Route path="contact" element={<Contact />} />
        <Route path="design" element={<Design />} />
        <Route path="jsoneditor/:data" element={<JsonEditor/>} />
        <Route path="bookcase" element={<Bookcase/>} />


        
        <Route path="java" element={<Java />} />
        <Route path="python" element={<Python />} />
        <Route path="csharp" element={<CSharp  />} />
        <Route path="nodejs" element={<NodeJS />} />
        <Route path="nodejs" element={<NodeJS />} />
        <Route path="createappliation" element={<CreateAppliation />} />
        <Route path="registerdeveloper" element={<RegisterDeveloper />} />
        <Route path="reactcarousel" element={<ReactCarousel />} />
        
        <Route path="login" element={<Login />} />
        
        <Route path="dynamicpage" element={<DynamicPage />} />
         <Route path="book" element={<Book />} />
        
        
        <Route path="awslambdaserverless" element={<AWSLambdaServerless />} />
        <Route path="microsoftazurefunctions" element={<MicrosoftAzureFunctions />} />
        <Route path="googlecloudfunctions" element={<GoogleCloudFunctions />} />
        <Route path="kubernetes" element={<Kubernetes />} />
        
        
        <Route path="register" element={<Register />} />
        <Route path="reset" element={<Reset />} />
        
        <Route path="*" element={<NoPage />} />
          
        </Route>
      </Routes>
    </>
  );
}

