import React from 'react'; 
import { getDatabase, ref, onValue , child, push, update,set } from "firebase/database";

import {  onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";

import { useAuthState } from "react-firebase-hooks/auth";

 
import {db, auth,  signInWithGoogle, event_dashboard, load_toolbar } from "../cfgfirebase";
// import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components'

import {    useRef } from "react";

 
import ReactDOM from "react-dom/client";
 

const PrimaryButtonCss = {
    "background-color": "darkcyan",
  "color": "white",

  "border-radius": "8px",
  "border-color": "transparent",
  "width": "250px",
  "padding": "14px",
  "font": "20px Georgia",
  "hover": {
    "background-color": "rgb(0,139,139,0.5)"
  } 
    
}

export const PrimaryButton = styled.button` 
  {{PrimaryButtonCss}}
`
 
let src_image = ''
let id =  'id'

    function isEmptyObject(value) {
    
         
    if (value  === 'null') { return true;}
     if (value  === 'undefined') { return true;}
       if (value  === undefined) { return true;}
    
 
    
  return Object.keys(value).length === 0 && value.constructor === Object;
}

let style_css_json = ''
let class_css = ""
let dataCollection = ""


  function  applyproperties(arr_properties) {
      console.log(arr_properties)
     
    
 let sentence =  isEmptyObject(arr_properties)
  if (sentence  === false) { 
   
    
    let fLen = arr_properties.length;

 
for (let i = 0; i < fLen; i++) {
   let key = Object.keys(arr_properties[i])[0]
    
      let value = Object.values(arr_properties[i])[0]
      
       switch (key)
       {
        case 'id':
          id  = value;
          break
        case 'src':
          src_image  = value;
          break
        case 'style':
          style_css_json  = value;
          break 
          case 'class':
          class_css  = value;
          break
        case 'class':
          dataCollection  = value;
          break
          
          
          
        default  :
        
          break
       }
    // console.log( value) ; 
 //android.widget.EditText
}
   
  }
  }

const spacing = "10px 10px";
const padding = "10px 10px";


 //props={propsChild }
 
   function handleSubmit(e) {
    // Prevent the browser from reloading the page
    e.preventDefault();
    // Read the form data
    const form = e.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    console.log(formJson);
    //  <div><LinearLayout views={ressponse.data.views} /></div>
  //   let metadata =  props.metadata
  // let context =    props.context
  }
  

       
       
       // Lưu array vào localStorage
         const saveToLocalStorage = (array) => {
           localStorage.setItem('items', JSON.stringify(array));
         };

         // Lấy array từ localStorage
         const loadFromLocalStorage = () => {
           const data = localStorage.getItem('items');

           return JSON.parse(data);

         };

export default function ImageView({props}) {
    
      const [user, loading, error] = useAuthState(auth);
    let uid = user.uid;
   console.log(props);

       
    
let navigate = useNavigate();
const handleClick = event=> {
    // 👇️ refers to the image element
    console.log(event.target);

    console.log('bobbyhadz.com');
    const form = event.target;
                const formData = new FormData(form);
                const formJson = Object.fromEntries(formData.entries());
                console.log('formJson');
                console.log(formJson);
                const myJSON = JSON.stringify(formJson);
                  console.log('myJSON');
                console.log(myJSON);
  };
  
  
  




  
  
  const shoot = (props) => {

        // const inputs = props.context.inputs
  
    // const setInputs =  props.context.setInputs
    
        // alert(props)   
        //  alert(props.action) 
         //  alert(props.action.command) 
       if ((props.action != '' ) && ( props.action  != undefined ))
       {
          let  command =  props.action.command
           if(command == 'back')
           {
                navigate('/');
           }
             if(command == 'post')
           {
             const  post_conlection =  props.action.post_conlection

                   var arr =loadFromLocalStorage();
//
//                console.log('array_field');
//                console.log(arr);
//                //to get values
//                for (let value of Object.values(arr)) {
//                     console.log(value);
//                     const myElement = document.getElementById(value);
//                     if (myElement != null)
//                          console.log(myElement.value );
//                }
                //Output: 1, 2, 3
               var data = {};
                //to get keys
                for (let key  of Object.keys(arr)) {
                     console.log(key);
                    let  id_control = arr[key]
                    console.log(id_control);
                      const myElement = document.getElementById(id_control);
                       if (myElement != null)

                       {
                        data[key] = myElement.value;
                        console.log(myElement.value );
                       }

                               //                 console.log(myElement.value );

                }
                //Output: one, two, three

//                console.log(props);
//
//
//                 let  list_field  = props.context.list_field
//


     
    // Using loop to insert key
    // value in Object
  
//                console.log("list_field" )
//                 console.log( list_field)
//
//               list_field.forEach (function(field_name) {
//console.log(field_name )
//                var valueObj = document.getElementById(field_name);
//                console.log(valueObj )
//                if(valueObj != null)
//                {
//
//                     var value = valueObj.value;
//                       console.log(value )
//                     data[field_name] = value;
//                  }
//
//
//                })

           
            // const subredditElementList = list_field.map(field_name => {
                
            
            // })
            
            
 
    
    
 
    
        const   json =  
          {
               "event_dashboard_current_day" : "event_dashboard_current_day_2023_06_18",
               "user_id" : props.context.app_user_id,
               "message_key" : "",
               "data" : data,
//               "list_field": list_field,
          
                "event_dashboard_sesion" : event_dashboard,
                "application" : props.context.application,
                "event" : props.context.event,
                "module" : "ad",
                "event_dashboard" : event_dashboard,
                "event_recycler_view" : "",
                "os": "web",
                "event_recycler_view_child" : ""
              
          }  
             const myJSON = JSON.stringify(json);
            
        const   post_data =  {
                                "application": props.context.application,
                                "dataCollection": post_conlection,
                                "json": myJSON,
                                "key": "key1",
                                "module": "core",
                                "os": "web",
                                "user_id": props.context.user_id
                            }
    
            
            
            
            
                  const postsRef= ref(db, 'request')
        // Get a key for a new Post.
        const newPostKey = push(child(ref(db), 'request')).key;
        const starCountRef = ref(db, 'request/' + newPostKey  );
             console.log(newPostKey );
               console.log(post_data );
        let bInssert = false
        {
            set(starCountRef, post_data)
            .then(() => {
            })
            .catch((error) => {
            });
            bInssert = true
        }
       }
      }
     };
  




  
  
  

  
  let  properties =  props.properties
  if(props.properties == '')
    {
        properties=  props
    }
    let style_css = {
  margin: spacing,
  padding: padding,
  width:  '120px',
  height:  '120px'
  // ...
}

    src_image = ''
     style_css_json = ''
     class_css = ""
    let styleVariant =  " width: '120px',height:  '120px' "
       applyproperties(properties );
     //  labelTxt =  "https://firebasestorage.googleapis.com/v0/b/resshare-request-cb5e2.appspot.com/o/icon%2Ficons8-left-100.png?alt=media&token=056bdc13-4138-43b8-852e-9f612712e35a"
     if(src_image  ===  '')
        {
            src_image =  props?.icon
            
        }
        
             if(style_css_json  !=  '')
        {
            style_css   =  style_css_json
           
 
            
        }
        var style1 = {
    position:'fixed',
    
     width: "150px",
    background:'red'
}
        
     const styles = {
    main1: {
      backgroundColor: "#f1f1f1",
      width: "100%",
    },
    inputText1: {
      padding: "10px",
      color: "red",
      width: "50%",
    },
  };
  
   const stylesClass = { bigblue: {
  "color": "DodgerBlue",
  "padding": "40px",
  "font-family": "Arial",
  "text-align": "center",
   "backgroundColor": "red",
   width: "1000px",
}}
//  <img   type="submit"   src={src_image}  id={id}  alt="react logo"   onClick={() => shoot(props)}              style={style_css} />
        
  return (
    <div  class={class_css} >
      <img       src={src_image}  id={id}  alt="react logo"    onClick={() => shoot(props)}                style={style_css} />
     </div>
  );
}
