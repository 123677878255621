import { SearchOutlined }from '@ant-design/icons';
import { getDatabase, ref, onValue , child, push, update,set } from "firebase/database";

import {  onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";

import { useAuthState } from "react-firebase-hooks/auth";

 
import {db, auth,  signInWithGoogle, event_dashboard, load_toolbar } from "../cfgfirebase";

import "./SearchView.css";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';


//https://mui.com/material-ui/react-grid/#variable-width-content
 
   
   const shoot = (props) => 
   {
     console.log(props)
     var text_seach = document.getElementById("search").value;
     
     const  post_conlection =  props.action.post_conlection

             var data =        {
                       "text_seach" : text_seach,
                       "type_seach" : "book" 
               
             };
           const   json =  
          {
               "event_dashboard_current_day" : "event_dashboard_current_day_2023_06_18",
               "user_id" : props.context.app_user_id,
               "message_key" : "",
               "data" : data,
          
                "event_dashboard_sesion" : "event_dashboard_sesion_ios_2023_06_18_09_03_14_083",
                "application" : props.context.application,
                "event" : props.context.event,
                "module" : "ad",
                "event_recycler_view" : props.context.event_recycler_view,
                "event_recycler_view_child" : props.context.event_recycler_view_child
              
          }  
             const myJSON = JSON.stringify(json);
            
        const   post_data =  {
                                "application": props.context.application,
                                "dataCollection": post_conlection,
                                "json": myJSON,
                                "key": "key1",
                                "module": "core",
                                "os": "web",
                                "user_id": props.context.user_id
                            }
    
            
            
            
            
                  const postsRef= ref(db, 'request')
        // Get a key for a new Post.
        const newPostKey = push(child(ref(db), 'request')).key;
        const starCountRef = ref(db, 'request/' + newPostKey  );
        let bInssert = false
        if(!bInssert)
        {
         // alert(bInssert) 
            set(starCountRef, post_data)
            .then(() => {
            //    alert("Data saved successfully!") 
              // Data saved successfully!
            })
            .catch((error) => {
              // The write failed...
            });
          
            bInssert = true
        }
 
     
   }
       


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
 
export default function  SearchView({props}) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>

        <Grid item xs={11}>
          
          <input type="text" placeholder="Search.."  name="search"  id="search"    style={{ width: '100%',height:  '35px'  }   }   />
        </Grid>
        <Grid item xs="auto">
         <SearchOutlined     onClick={() => shoot(props)}    />
         </Grid>
      </Grid>
    </Box>
  );
} 


 