

import * as React from "react";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
} from "react-router-dom";



const Blogs = () => {
    
const { id } = useParams();    
  
return (
	<div>
 
     <div>
      <a href="https://dev.to/shaundai/making-styles-repeatable-dynamic-styling-in-react-using-styled-components-5afl" target="_blank" rel="noreferrer" >
        <img src= "https://res.cloudinary.com/practicaldev/image/fetch/s--h-TgdajY--/c_imagga_scale,f_auto,fl_progressive,h_420,q_auto,w_1000/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/ghdk4r3kr7w042kgb5ec.png"     alt="colab" style={{ width: '380px',height:  '240px'  }} />
      </a>
    </div>
 
 
 
 
 
 
 
    

  </div>
);
 
};

export default Blogs;