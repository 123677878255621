import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import "./GeneralForm.css";
import LinearLayout from '../components/LinearLayout';


import LinearLayoutContext from '../components/LinearLayoutContext';
import LinearLayoutContextDesign from '../components/LinearLayoutContextDesign';

import { Outlet    } from "react-router-dom";
import Layout from '../views/Layout';
import Navigation from '../components/Navigation';
import Searchbar from '../components/Searchbar';
import Application from "../pages/Application";
import Blogs from "../pages/Blogs";
import Contact from "../pages/Contact";
import NoPage from "../pages/NoPage";
import LeftMenuAntd from '../components/LeftMenuAntd';
import Right from '../components/Right';
import React, { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import {getCurrentTime, db, auth,  signInWithGoogle, event_dashboard, load_toolbar } from "../cfgfirebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import { getDatabase, ref, child, push, update,set } from "firebase/database";
import {   onValue   } from "firebase/database";
import {  onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import {    useRef } from "react";

const ressponse = {
  "app_user_id": "-NIVTrQEQRtKteCwnEjZ",
  "application": "book",
  "count_message": 7,
  "data": {
    "properties": [
      {
        "layout_width": "match_parent"
      },
      {
        "background": "@color/white"
      },
      {
        "layout_height": "match_parent"
      },
      {
        "orientation": "vertical"
      }
    ],
    "views": [
      {
        "properties": [
          {
            "layout_width": "match_parent"
          },
          {
            "background": "@drawable/bg_button_subapp"
          },
          {
            "id": "@+id/app_bar_layout"
          },
          {
            "layout_height": "45dp"
          },
          {
            "theme": "@style/AppTheme.AppBarOverlay"
          }
        ],
        "views": [
          {
            "properties": [
              {
                "layout_width": "match_parent"
              },
              {
                "id": "@+id/toolbar"
              },
              {
                "layout_height": "match_parent"
              },
              {
                "popupTheme": "@style/AppTheme.PopupOverlay"
              }
            ],
            "views": [
              {
                "properties": [
                  {
                    "layout_width": "match_parent"
                  },
                  {
                    "id": "@+id/relative_toool_bar"
                  },
                  {
                    "layout_height": "match_parent"
                  },
                  {
                    "orientation": "horizontal"
                  }
                ],
                "views": [
                  {
                    "action": {
                      "command": "back"
                    },
                    "properties": [
                      {
                        "layout_width": "46dp"
                      },
                      {
                        "id": "@+id/btn_back"
                      },
                      {
                        "layout_height": "46dp"
                      },
                      {
                        "scaleType": "centerInside"
                      },
                      {
                        "src": "@drawable/ic_back_white_24dp"
                      },
                      {
                        "layout_alignParentLeft": true
                      }
                    ],
                    "widget": "android.widget.ImageView"
                  },
                  {
                    "properties": [
                      {
                        "layout_width": "wrap_content"
                      },
                      {
                        "id": "@+id/tv_title"
                      },
                      {
                        "layout_height": "wrap_content"
                      },
                      {
                        "text": "Giới thiêu về ứng dụng"
                      },
                      {
                        "textSize": "16sp"
                      },
                      {
                        "layout_toRightOf": "@+id/btn_back"
                      },
                      {
                        "layout_gravity": "center_horizontal"
                      },
                      {
                        "layout_marginTop": "16dp"
                      },
                      {
                        "textColor": "@color/white"
                      }
                    ],
                    "widget": "android.widget.TextView"
                  }
                ],
                "widget": "android.widget.RelativeLayout"
              }
            ],
            "widget": "androidx.appcompat.widget.Toolbar"
          }
        ],
        "widget": "com.google.android.material.appbar.AppBarLayout"
      },
      {
        "properties": [
          {
            "layout_width": "match_parent"
          },
          {
            "background": "@drawable/bg_gray_border"
          },
          {
            "paddingLeft": "20dp"
          },
          {
            "layout_height": "match_parent"
          },
          {
            "paddingRight": "20dp"
          },
          {
            "paddingBottom": "30dp"
          },
          {
            "orientation": "vertical"
          },
          {
            "paddingTop": "10dp"
          }
        ],
        "views": [
          {
            "properties": [
              {
                "layout_width": "match_parent"
              },
              {
                "id": "@+id/tile_name_tile"
              },
              {
                "layout_height": "600dp"
              },
              {
                "text": "Đây là ứng dụng tìm kiếm sách gần nơi người đọc cần\nI. Người dùng vào mục: Thông tin liên hệ gồm số điện thoại và địa chỉ để có thể trao đổi sách với những người khác\nII. Thêm sách\n \t1. Các cá nhân tổ chức tình nguyện viên cung cấp sách vào mục: Thêm sách\n \t2. Nhập thông tin rồi ấn (V).\n \t3. Khi nhận được thông tin của người mượn sách thì bấm (Đồng ý) nếu muốn cho mượn. \nIII. Tìm kiếm \n \t1. Người tìm sách vào mục: Tìm kiếm, khai báo thông tin rồi ấn Tìm. Khi nhận danh sách người cho mượn thì bấm vào chọn sách.\nIV. Chú ý: \n   Sách đưa lên phải là sách được phép lưu hành ở Việt Nam, tài khoản đưa các sách có nội dung phản cảm, sách vi phạm pháp luật, không phù hợp với thuần phong mỹ tục sẽ bị khoá tài khoản. Người dùng đánh giá sách bẩn bằng cách cho 1 * hoặc Báo vi phạm nội dung phản cảm, báo người dùng vi phạm tiêu chuẩn. Với sách hay bạn cho nhiều sao."
              },
              {
                "textSize": "14sp"
              },
              {
                "layout_marginTop": "13dp"
              },
              {
                "textColor": "@color/browser_actions_text_color"
              }
            ],
            "widget": "android.widget.TextView"
          }
        ],
        "widget": "android.widget.LinearLayout"
      }
    ],
    "widget": "android.widget.LinearLayout"
  },
  "date_time": 1683817517977,
  "event": "event_ios_2023_05_11_22_05_17_658",
  "key_data": "json_layout",
  "parameter": "",
  "screen_name": "",
  "session": "-NVAL4B7YhgCukNBRhDt",
  "type": "json_layout",
  "user_id_destination": "0s1gRycDo2hw6InZH1uAaLqkZva2"
}


function inssertDB(starCountRef, request_loadform) {
    // Prevent the browser from reloading the page
            
        set(starCountRef, request_loadform)
            .then(() => {
                // alert("Data saved successfully!") 
              // Data saved successfully!
            })
            .catch((error) => {
              // The write failed...
            });
  }
 
  
  
  
  
  
  // class GeneralForm extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     name: '',
  //     nickname: '',
  //     country: '',
  //     percentage: 0,
  //     image: null,
  //   }
  //   this.handleInputChange = this.handleInputChange.bind(this);
  // }

  // handleInputChange(event) {
  //   const target = event.target;
  //   const value = target.value;
  //   const name = target.name;
  //   this.setState({
  //     [name]: value
  //   });
  // }

  
  
                 
                
 
  
  
         // Lưu array vào localStorage
           const saveToLocalStorage = (array) => {
             localStorage.setItem('items', JSON.stringify(array));
           };

           // Lấy array từ localStorage
           const loadFromLocalStorage = () => {
             const data = localStorage.getItem('items');

             return JSON.parse(data);

           };
  

const GeneralForm = () => {

var array_field =   {};

  // Lưu array vào localStorage
              saveToLocalStorage(array_field)  ;

  
const currentTime = getCurrentTime();
const event = 'event_web_loadform_'+ currentTime;
const event_recycler_view = 'event_recycler_view_'+ currentTime;
const event_recycler_view_child = 'event_recycler_view_child_'+ currentTime;
  
const count = useRef(0);
  
     const [inputs, setInputs] = useState([]);
        
    const list_field = useRef([]);
      

  
   

  const [gridMainDashboard1Count, setGridMainDashboard1Count] = useState([]);
   

   
  const layouts = useRef([]);
   

    
  const data_recycler_view = useRef([]);
     const [recycleMainDashboard1Count, setRecycleMainDashboard1Count] = useState([]);
     
      const data_recycler_view_child = useRef([]);
     const [recycleViewChild, setRecycleViewChild] = useState([]);
 
  
  const { id } = useParams();    
  
  let arr = id.split('____');
  let reference = arr[0]
// Create a Map

  let application = arr[1]
  let app_user_id = arr[2]
    const [user, loading, error] = useAuthState(auth);
    let uid = user.uid;
  
  let context = {
    application: application,
    reference: reference,
    app_user_id: app_user_id,
    inputs: inputs,
    setInputs: setInputs,  
    comment: "comment12",
    user_id: uid,
     event: event,
    list_field: list_field,
    event_recycler_view: event_recycler_view,
    event_recycler_view_child: event_recycler_view_child,
    data_recycler_view:data_recycler_view,
    data_recycler_view_child:data_recycler_view_child
          
    

  }
 
 
  
   const propsLinearLayout = {
         views: ressponse.data.views,
         context: context  
        
      }  
  
  

// export default function RecyclerView({ props } ) {
    
    // const context = props.context
    // const user_id = props.context.user_id
    // const event_recycler_view = props.context.event_recycler_view
    // const currentTime = getCurrentTime();
    // const event_recycler_view_currentTime =  event_recycler_view +  currentTime
     


      
 

    
    useEffect(() => {
   
    if (loading) {
      // maybe trigger a loading screen
       return;
    }
    if (!user) 
    {
       // navigate("/login");
    }
    else
    {
          uid = user.uid;
          
          
          
              const commentsRefdata_recycler_view = ref(db, "response/" + uid+  '/' + event_recycler_view );
    onChildAdded(commentsRefdata_recycler_view, (data) => {
   
   let message_type = data.val().message_type  
   
   
   if("RecyclerViewGrid" === message_type)
   {
     //RecyclerViewGrid child
     
     
      const messagetrecyclechild = {
         data: data.val(),
         context: context  

    }  
   
       data_recycler_view_child.current.push(messagetrecyclechild);
      setRecycleViewChild([...recycleViewChild, messagetrecyclechild]);

   }
   else
   {
     //RecyclerView
    
    const messagetrecycle = {
         data: data.val(),
         context: context  

    }  
   
       data_recycler_view.current.push(messagetrecycle);
        
      setRecycleMainDashboard1Count([...recycleMainDashboard1Count, messagetrecycle]);
   }
 })
    
     
      
      
      
        const  request_loadform = {
          application: application,
          dataCollection: "draft/core/get_layout_data",
          
          json: "{\n  \"application\" : \"" + application + "\",\n  \"reference\" : \"" + reference + "\",\n  \"module\" : \"module\",\n  \"screen_name\" : \"\",\n  \"parameter\" : \"\",\n  \"data\" : null,\n  \"user_id\" : \"" + app_user_id +  "\",\n  \"OS\" : \"IOS\",\n  \"event\" : \"" + event + "\"\n}",
          key: "key1",
          module: "core",
          channel: "web",
          user_id: uid
        }
       const postsRef= ref(db, 'request')
        // Get a key for a new Post.
        const newPostKey = push(child(ref(db), 'request')).key;
        const starCountRef = ref(db, 'request/' + newPostKey  );
        let bInssert = false
        if(!bInssert)
        {
         // alert(bInssert) 
            set(starCountRef, request_loadform)
            .then(() => {
            //    alert("Data saved successfully!") 
              // Data saved successfully!
            })
            .catch((error) => {
              // The write failed...
            });
          
            bInssert = true
        }


        
        const commentsRef = ref(db, "response/" + uid+  '/' + event );
      

       
      onChildAdded(commentsRef, (data) => {
        
          //Thêm sách thành công
              let msg_type = data.val().msg_type   
         if( msg_type == "command" )
         {
           let message =   data.val().data.screen.show_alert_dialog
          alert(message)  
         }
         
         
         
          //seach boookkkkkkkkkkk
         const  methodName  =   data?.val()?.data?.script?.list_command?.[1]?.methodName
  
         console.log(methodName)
         
         if( methodName == "showAlertDialog" )
         {
           let  messageShow  =   data?.val()?.data?.script?.list_command?.[1]?.methodParam;
            alert(  messageShow[0]  )
         }
        
         

 
        
        
         let type = data.val().type
        if( type == "json_layout" )
        {
        
          let message = {
                layout: data.val().data,
                context: {
                        reference: reference,
                        application : data.val().application,
                        app_user_id : data.val().app_user_id,
                        inputs: inputs,
                        setInputs: setInputs,

                        user_id: uid,
                        event: event,
                        list_field:list_field,
                        event_recycler_view: event_recycler_view,
                        event_recycler_view_child: event_recycler_view_child,
                        data_recycler_view:data_recycler_view,
                        data_recycler_view_child:data_recycler_view_child,
                        comment: "comment123",
                        
                        }
                            
          }
         
       let  application = data.val().application


        // let array = [];
        // array.push([message]);
        // console.log('array >>', array);
    
      //  count.current = count.current + 1;
       layouts.current.push(message);
        
        setGridMainDashboard1Count([...gridMainDashboard1Count, message]);
        
 
        //fruits.has("apples")
        
           
        
 
        
      }
      }); 
 
    }
    
  }, [user, loading]);
 
  


  const submit = (e) => {
    e.preventDefault();
    console.log("inputs")
        
    console.log(inputs)
}
 
if(window.design_mode === false)
  {
             return (



                  <Layout>
                   <div class="form-style-10">


                    <div class="inner-wrap"  >
                         <div><LinearLayoutContext views={layouts.current} /></div>
                    </div>
                   </div>
                   </Layout>


            )
  }
    else
    {

                        const   style_middle_block  = {
                        "width" : "100%",
                        "display": "flex"
                        }
                        const  middle_block_left = {
                        "width": "75%"
                        }
                        const  middle_block_ringht = {
                        "width": "25%"
                        }
                     return (

                      <div  style={style_middle_block}>

                        <div style={middle_block_left}  >


                        <Layout>
                           <div class="form-style-10">


                            <div class="inner-wrap"  >
                                 <div><LinearLayoutContext views={layouts.current} /></div>
                            </div>
                           </div>
                           </Layout>



                        </div>
                        <div style={middle_block_ringht}  >

                        <Layout>
                           <div class="form-style-10">


                            <div class="inner-wrap"  >
                                 <div><LinearLayoutContextDesign  views={layouts.current} /></div>
                            </div>
                           </div>
                           </Layout>


                        </div>


                      </div>

                )

    }


}
export default GeneralForm;