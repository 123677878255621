import "./RelativeLayout.css";
import React from 'react';
import shortid from 'shortid';

import LearnReactView from '../views/LearnReactView';
import ReactView from '../views/ReactView';
import JavaScriptView from '../views/JavaScriptView';
import NullView from '../views/NullView';
import TextView from './TextView';
import ImageView from './ImageView';
import EditText from './EditText';
import GridDashboard from './GridDashboard';
import LinearHorizontalLayout from './LinearHorizontalLayout';
import GridView from './GridView';

import Spinner from './Spinner';
import SearchView from './SearchView';


        
        

import "./Navigation.css";

 


 
 import { Outlet, Link } from "react-router-dom";
import LinearLayout from '../components/LinearLayout';

import Layout from '../views/Layout';

import Searchbar from '../components/Searchbar';

 
import Application from "../pages/Application";
import Blogs from "../pages/Blogs";
import Contact from "../pages/Contact";
import NoPage from "../pages/NoPage";


import LeftMenuAntd from '../components/LeftMenuAntd';
import Right from '../components/Right';


 
import {  useNavigate } from "react-router-dom";
import {db, auth,  signInWithGoogle, event_dashboard, load_toolbar } from "../cfgfirebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
 
import { getDatabase, ref, child, push, update,set } from "firebase/database";



import {   onValue   } from "firebase/database";

import {  onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";









import "./Navigation.css";
 
 import { onAuthStateChanged } from "../cfgfirebase";


let labelTxt = 'Name (4 to 8 characters)'
let id =  'id'

    function isEmptyObject(value) {
    
         
    if (value  === 'null') { return true;}
     if (value  === 'undefined') { return true;}
       if (value  === undefined) { return true;}
    
 
    
  return Object.keys(value).length === 0 && value.constructor === Object;
}
 

 
 let  textarea =''
  function  applyproperties(arr_properties) {
     
 
 textarea =''
 let sentence =  isEmptyObject(arr_properties)
  if (sentence  === false) { 
  
    let fLen = arr_properties.length;

 
for (let i = 0; i < fLen; i++) {
   let key = Object.keys(arr_properties[i])[0]
    
      let value = Object.values(arr_properties[i])[0]
      
       switch (key)
       {
        case 'id':
          id  = value;
          break
        case 'text':
          labelTxt  = value;
          break
        case 'textarea':
          textarea  = value;
          break
          case 'style':
          style_css_db  = value;
          break      
        default  :
        
          break
       }
    // console.log( value) ; 
 
}
   
  }
  }
  
    let style_css_db = ''
    let style_css = {


   
  "height":  '50px'
  // ...
}


 






export default function RelativeLayout({ props }   ) {
  let views =  props.views
  let context =    props.context
  let layout_context=    props.layout_context
  
   console.log("RelativeLayout   props: ", props)
    style_css_db = ''
  applyproperties(props.properties)
  if(  style_css_db === '' )
   {
       style_css_db =  style_css
   }
    console.log("RelativeLayout   style_css: ", style_css)
 
 //   return (
//     <div>
//       <div>
//           <ul className="gridDashboard" >
//             {  
   
  const subredditElementList = views.map(subreddit => {
    let widget = subreddit.widget
      let propsLinearLayout = {
         properties: subreddit.properties,
         views: subreddit.views,
         context: context,
         layout_context:layout_context,
          data_field_value: props.data_field_value
        
      } 
      
       let propsChild = {
         action: subreddit.action,
         widget: subreddit.widget,
         properties: subreddit.properties,
         views: subreddit.views,
         context: context,
         layout_context:layout_context,
          data_field_value: props.data_field_value
        
      } 
    switch (widget) {
      case 'reactjs':
        return <ReactView key={shortid.generate()} />;
      case 'learnreactjs':
        return <LearnReactView key={shortid.generate()} />;
      case 'javascript':
        return <JavaScriptView key={shortid.generate()} />;
         
     case 'android.widget.TextView':
        return <TextView   propsChild={propsChild} />;
    case 'TextView':
        return <TextView   propsChild={propsChild} />;
        
        
         case 'android.widget.EditText':
        return <EditText   propsChild={propsChild} />;
    case 'EditText':
        return <EditText   propsChild={propsChild} />;

                 case 'android.widget.SearchView':
        return <SearchView   props={propsChild} />;
                         case 'androidx.appcompat.widget.SearchView':
        return <SearchView   props={propsChild} />;
        
        
        case 'SearchView':
        return <SearchView   props={propsChild} />;
        

        
                         case 'android.widget.Spinner':
        return <Spinner   propsChild={propsChild} />;
    case 'Spinner':
        return <Spinner   propsChild={propsChild} />;
        

            
       
      case "android.widget.ImageView":
      return <ImageView   props={propsChild } />;
      
      case 'ImageView':
      return <ImageView   props={propsChild } />;
      
      
      case 'android.widget.LinearLayout':
   
       
      return <LinearLayout   props={propsLinearLayout}   />;
     case 'LinearLayout':
       
      
        
      return <LinearLayout   props={propsLinearLayout}  />;
      
    
      
      case 'NestedScrollView':
      return <LinearLayout   props={propsLinearLayout}  />;
      
      case 'androidx.core.widget.NestedScrollView':
      return <LinearLayout   props={propsLinearLayout}  />;
      

      
      case 'androidx.cardview.widget.CardView':
      return <LinearLayout props={propsLinearLayout}  />;
            
      case 'CardView':
      return <LinearLayout  props={propsLinearLayout}  />;
      
      
      case 'android.widget.RelativeLayout':
      return <RelativeLayout  props={propsLinearLayout}  />;
            
      case 'RelativeLayout':
      return <RelativeLayout  props={propsLinearLayout}  />;     
           
      case 'androidx.appcompat.widget.Toolbar':
      return <LinearLayout   props={propsLinearLayout}  />;
            
      case 'Toolbar':
      return <LinearLayout  props={propsLinearLayout}  />;
      
      
      case 'android.widget.LinearHorizontalLayout':
     return <LinearLayout  props={propsLinearLayout}  />;
            
      case 'LinearHorizontalLayout':
     return <LinearLayout  props={propsLinearLayout}  />;
      
      
 
      
      
      
      
      
      case 'com.google.android.material.appbar.AppBarLayout':
      return <LinearLayout   props={propsLinearLayout}  />;
            
      case 'AppBarLayout':
      return <LinearLayout   props={propsLinearLayout}  />;  
      
      case 'GridDashboard':
     return <LinearLayout  props={propsLinearLayout}  />;
       case 'android.widget.GridDashboard':
   return <LinearLayout  props={propsLinearLayout}  />;
      
      
      
      case 'GridView':
      let propsGridView0 = {
         metadata: subreddit,
         context: context  
        
      }  
      return <GridView   props={propsGridView0}      />;   
       case 'android.widget.GridView':
          let propsGridView1 = {
         metadata: subreddit,
         context: context  
        
      }  
      return <GridView   props={propsGridView1}    />;
      
      
      
      
 

       
      default:
        return (
          <NullView
            key={widget}
          >{`"r/${widget}" - not implemented`}</NullView>
        );
    }
  });
  
//   <div class="left"><a href="">Contact1</a></div>
  /* parent container */
const parentsdd =  {
	"display": "flex",
	"flex-direction": "row",
	"justify-content": "space-between"
}
const mainnav = {
    "display": "flex"
}
    
const  push = {
    "margin-left": "auto"
}
  


 
  return (
 

        

         
  
 
        
         
  
               
          <nav>
            <ul class="main-nav"  style={style_css_db} >
              {subredditElementList}
                   
            </ul>
         </nav>
                
     
     
        
  
    
     
    
 
 
        


  ) ;
}
