
 



import "../styles.css";
 import "../App.css";
 
 import { Outlet, Link } from "react-router-dom";
import LinearLayout from '../components/LinearLayout';

import Layout from '../views/Layout';
import Navigation from '../components/Navigation';
import Searchbar from '../components/Searchbar';

 
import Application from "../pages/Application";
import Blogs from "../pages/Blogs";
import Contact from "../pages/Contact";
import NoPage from "../pages/NoPage";


import LeftMenuAntd from '../components/LeftMenuAntd';
import Right from '../components/Right';


import React, { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import {db, auth,  signInWithGoogle, event_dashboard, load_toolbar } from "../cfgfirebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
 
import { getDatabase, ref, child, push, update,set } from "firebase/database";



import {   onValue   } from "firebase/database";

import {  onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";


//https://firebase.google.com/docs/database/web/read-and-write#web_value_events
 



 
 
// const views = [
//   'reactjs',
//   'TextView',
//   'learnreactjs',
//   'pics',
//   'reactjs',
//   'learnreactjs',
//   'svelte',
//   'javascript',
//   'learnreactjs'
// ];
const datalayout = {
  "properties": [
    {
      "layout_width": "match_parent"
    },
    {
      "background": "@drawable/bg_white_selector"
    },
    {
      "orientation": "vertical"
    },
    {
      "layout_height": "380dp"
    }
  ],
  "views": [
    {
      "properties": [
        {
          "layout_width": "300dp"
        },
        {
          "background": "@drawable/bg_white_selector"
        },
        {
          "id": "@+id/txtDescription"
        },
        {
          "layout_height": "45dp"
        },
        {
          "textSize": "16sp"
        },
        {
          "text": "Các chức năng ứng dụng mượn sách"
        },
        {
          "ellipsize": "end"
        },
        {
          "maxLines": 2
        },
        {
          "textColor": "@color/browser_actions_text_color"
        },
        {
          "layout_height:": "30dp"
        }
      ],
      "widget": "android.widget.TextView"
    },
    {
      "properties": [
        {
          "numColumns": 3
        },
        {
          "id": "@+id/dashboardGridView"
        },
       
       
        {
          "layout_height": "370dp"
        }
      ],
      "widget": "GridView"
    }
  ],
  "widget": "android.widget.LinearLayout"
};
 
 export  let grid_main_data = [ datalayout  ];
  
const LayoutMain = () => {
    
      const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  
   var  load_dashboard = false;

  
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
       return;
    }
    if (!user) 
    {
        navigate("/login");
    }
    else
    if(load_dashboard==false)

    {
   console.log("load dashboard")
   load_dashboard= true
        const uid = user.uid;
        const  request_grid_main = {
          application: "resshare",
          dataCollection: "draft/core/get_home_layout",
          json: "{\"reference\":\"system_setting/layout/web/home\",\"user_id\":\"" +   uid      + "\",\"channel\":\"web"       +        "\",\"event_dashboard\":\"" +   event_dashboard  + "\",\"event\":\""+  load_toolbar + "\"}",
           
          key: "key1",
          module: "core",
          channel: "web",
         
          user_id: uid
        }
       const postsRef= ref(db, 'request')
        // Get a key for a new Post.
        const newPostKey = push(child(ref(db), 'request')).key;
        const starCountRef = ref(db, 'request/' + newPostKey  );
     //   alert(" insert request")
        
 
  
            set(starCountRef, request_grid_main)
            .then(() => {
              // Data saved successfully!
            })
            .catch((error) => {
              // The write failed...
            });
          //   navigate("/application");
          
          
          
          
           

 
  
    
    
    
    
    }
    
    
  }, [user, loading]);
  
 
  return (
     
            <div className="grid-container">
              <div className="Header">
              <div><Navigation   /></div>
              
              </div>
              <div className="Left">
             
             
            
              
               <div><LeftMenuAntd   /></div>
              
              </div>
              <div className="Main">
              
        
              
                <>
                  <nav>
      
                  </nav>
            
                  <Outlet />
                </>

              </div>  
              {/* <div className="Right">
             
               <div><Right   /></div>
              
              </div> */}
              <div className="Footer">

              <a href=" http://www.saolait.com">
               <div >SaolaIT Technology</div>
              </a>
              <div>©Copyrighted by SaolaIT</div>
             
              </div>
            </div>

    )
};

export  default LayoutMain;




