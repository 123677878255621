import { Outlet, Link } from "react-router-dom";
import React from "react";



import "./RadioButton.css";


let src_image = ''
let id =  'id'

    function isEmptyObject(value) {
    
         
    if (value  === 'null') { return true;}
     if (value  === 'undefined') { return true;}
       if (value  === undefined) { return true;}
    
 
    
  return Object.keys(value).length === 0 && value.constructor === Object;
}

let style_css_json = ''
 let text= ''
 let checked= false
 
  function  applyproperties(arr_properties) {
      console.log(arr_properties)
     
    
 let sentence =  isEmptyObject(arr_properties)
  if (sentence  === false) { 
   
    
    let fLen = arr_properties.length;

 
for (let i = 0; i < fLen; i++) {
   let key = Object.keys(arr_properties[i])[0]
    
      let value = Object.values(arr_properties[i])[0]
      
       switch (key)
       {
        case 'id':
          id  = value;
          break
        case 'text':
          text  = value;
          break
         case 'checked':
          checked  = value;
          break         
          
          

        case 'style':
          style_css_json  = value;
          break          
        default  :
        
          break
       }
    // console.log( value) ; 
 //android.widget.EditText
}
   
  }
  }

const spacing = "10px 10px";
const padding = "10px 10px";
 
 
export default function RadioButton( {props} ) {
  
    text= ''
       checked = true
      let  properties =  props.properties
  
     applyproperties(properties )
     
    
  return (
        <div class="topnavRadioButton">
  
        
          <label><input type="radio" name="myRadio" value= {checked}  /> {text} </label>
         
               
      
          
          
        </div>
        
  )
  
  
  
}

 