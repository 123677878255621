import React from 'react';
import shortid from 'shortid';

import LearnReactView from '../views/LearnReactView';
import ReactView from '../views/ReactView';
import JavaScriptView from '../views/JavaScriptView';
import NullView from '../views/NullView';
import TextView from './TextView';
import ImageView from './ImageView';
import EditText from './EditText';
import GridDashboard from './GridDashboard';
import LinearHorizontalLayout from './LinearHorizontalLayout';
import GridView from './GridView';
import LinearLayout from './LinearLayout';
import LinearLayoutDesign from './LinearLayoutDesign';

import RelativeLayout from './RelativeLayout';
import CircleImageView from './CircleImageView';
      import TextViewDesign from './TextViewDesign';


import {    useRef } from "react";

import "./LinearLayoutContext.css";













export default function LinearLayoutContextDesign  ({ views } ) {
console.log("LinearLayoutContext views props: ", views)
 let list_field  =  [];

  const subredditElementList = views.map(message => {
     console.log("LinearLayoutContext views message: ", message)
    let subreddit = message.layout
    let context =    message.context;
     let layout_context = message.layout_context;
    context.list_field = list_field
    let widget = subreddit.widget

     let propsLinearLayout = {
         data_field_value: message?.message?.data?.layout_data_field,
         properties: subreddit.properties,
         views: subreddit.views,
         context: context ,
         layout_context: layout_context


      }
             let propsChild = {
         data_field_value: message?.message?.data?.layout_data_field,
         action: subreddit.action,
         widget: subreddit.widget,
         properties: subreddit.properties,
         views: subreddit.views,
         context: context ,
          layout_context: layout_context

      }
                        //       inputFieldsFirebase: inputFieldsFirebase,


                        // setInputFieldsFirebase: setInputFieldsFirebase


    switch (widget) {





      case 'de.hdodenhof.circleimageview.CircleImageView':






      case 'reactjs':
        return <ReactView key={shortid.generate()} />;
      case 'learnreactjs':
        return <LearnReactView key={shortid.generate()} />;
      case 'javascript':
        return <JavaScriptView key={shortid.generate()} />;

     case 'android.widget.TextView':

    case 'TextView':



         case 'android.widget.EditText':

    case 'EditText':



                 case 'android.widget.SearchView':

    case 'SearchView':




                         case 'android.widget.Spinner':

    case 'Spinner':






      case "android.widget.ImageView":


      case 'ImageView':





            case "android.widget.RadioGroup":


      case 'RadioGroup':
  return <TextViewDesign   propsChild={propsChild} />;



            case 'RecyclerView':


            case 'androidx.recyclerview.widget.RecyclerView':





      case 'android.widget.LinearLayout':



     case 'LinearLayout':





      case 'NestedScrollView':

      case 'androidx.core.widget.NestedScrollView':




      case 'androidx.cardview.widget.CardView':


      case 'CardView':



      case 'android.widget.RelativeLayout':


      case 'RelativeLayout':


      case 'androidx.appcompat.widget.Toolbar':


      case 'Toolbar':



      case 'android.widget.LinearHorizontalLayout':


      case 'LinearHorizontalLayout':


       case 'HorizontalScrollView':




       case 'android.widget.HorizontalScrollView':



      case 'com.google.android.material.appbar.AppBarLayout':


      case 'AppBarLayout':


      case 'GridDashboard':

       case 'android.widget.GridDashboard':




      case 'GridView':

       case 'android.widget.GridView':

  return <LinearLayoutDesign   props={propsLinearLayout}   />;







      default:
        return (
          <NullView
            key={shortid.generate()}
          >{`Dang phat trien: ` + widget}</NullView>
        );
    }


  });

 // return <div className="container">{subredditElementList}</div>;



let style_css_db = {




        "background-color":  '#00BFFF',

        'border': '10px solid #257C9E',
        'padding': '30px',

        'margin-bottom': '30px',



        // ...
}
    let style_css = {

  "width":  '1064px',

  "height":  '50px',
  "background-color":  '#00BFFF'

  // ...
}
    return (
                  <div    >

                     <div           >{subredditElementList}</div>
                     </div>

    );

}
