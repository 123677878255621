 
import { Outlet, Link } from "react-router-dom";
 



import "../styles.css";
 import "../App.css";
import Layout from '../views/Layout';
import Navigation from '../components/Navigation';
import Searchbar from '../components/Searchbar';
 
 
 
 
 
import Contact from "./Contact";
import NoPage from "./NoPage";
 import LinearLayout from '../components/LinearLayout';
 import LinearLayoutContext from '../components/LinearLayoutContext';
 
 
  import {grid_main_data} from './LayoutMain';
 
 
 import { useNavigate } from 'react-router-dom';

import { getDatabase, ref, onValue , child, push, update,set } from "firebase/database";

import {  onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";



 
import {db, auth,  signInWithGoogle, event_dashboard, load_toolbar } from "../cfgfirebase";

import React, { useEffect, useState, getInitialState } from "react";
import {    useRef } from "react";
 
import { useAuthState } from "react-firebase-hooks/auth";
import ReactDOM from "react-dom/client";
 
const Design = () => {
  window.design_mode =   true;
  let navigate = useNavigate();
  
  const [user, loading, error] = useAuthState(auth);
  const [grid_main_dashboard, setGrid_main_dashboard] = useState([]);
   const grid_main_dashboard1 =  []
    const count = useRef(0);
   const [gridMainDashboard1Count, setGridMainDashboard1Count] = useState([]);
   const [test, setTest] = useState(false);
   const layouts = useRef([]);
    const application_key = useRef( new Map());
 
   let  props = {
           
          
           
       }
  //const [inputValue, setInputValue] = useState("");
  

  useEffect(() => {
    setTest(!test)
    console.log(test);
  }, []);
 
  useEffect(() => {
   
    if (loading) {
      // maybe trigger a loading screen
       return;
    }
    if (!user) 
    {
        navigate("/login");
    }
    else
    {
        const uid = user.uid;
        const commentsRef = ref(db, "response/" + uid+  '/' + event_dashboard );
     props = {
           
           user_id:  user.uid,
           event_dashboard: event_dashboard
           
       }
       
      onChildAdded(commentsRef, (data) => {
          let message = {
                message: data.val(),
                layout: data.val().data.layout,
                context: {
                        application : data.val().application,
                        app_user_id : data.val().app_user_id,
                        user_id:  user.uid,
                        
                        }
          }
         
       let  application = data.val().application


        // let array = [];
        // array.push([message]);
        // console.log('array >>', array);
    
        count.current = count.current + 1;
       
        setGridMainDashboard1Count([...gridMainDashboard1Count, message]);
        
      
        //fruits.has("apples")
        if(!application_key.current.has(application))
        {
           // application_key.current.set(application,true);
        console.log('application  main >>', application);
            layouts.current.push(message);
              console.log('message  main >>', message);
        }
        
      }); 
 
    }
    
  }, [user, loading]);
  
  
       

   return ( 
       

      <div>
          <div><Searchbar    props={props}   /></div>
         
            <div>
                        <div className="content-element-list">




                                <LinearLayoutContext views={layouts.current} />



                       </div>
           
             
            

              
            </div>
          
       </div>   
    )


};

export default Design;



 