const Kubernetes = () => {
       return (
      <div>
         
          
            <div>
           
           <h1> Các bước cài đặt Kubernetes cluster và deploy ứng dụng trên EC2  Amazon Linux</h1>
            <h3>
           
             
             
                Chọn máy Instance type
                m5.xlarge
                Kiến trúc Kubernetes cluster 
                Phần I. Cài đặt cho máy làm Master Node
                • Trong bài đang sử dụng địa chỉ Ip của Master Node là: 10.0.0.18
                1. Cài đặt môi trường Docker
                • sudo yum install docker
                • sudo systemctl start docker
                • sudo systemctl status docker
                • sudo systemctl enable docker
                2. Thiết lập repo Kubernetes
                cat  EOF | sudo tee /etc/yum.repos.d/kubernetes.repo
                [kubernetes]
                name=Kubernetes
                baseurl=https://packages.cloud.google.com/yum/repos/kubernetes-el7-\$basearch
                enabled=1
                gpgcheck=1
                repo_gpgcheck=1
                gpgkey=https://packages.cloud.google.com/yum/doc/yum-key.gpg https://packages.cloud.google.com/.../rpm-package-key.gpg
                exclude=kubelet kubeadm kubectl
                EOF
                3. Cài đặt kubelet kubeadm kubectl
                • sudo yum install -y kubelet kubeadm kubectl --disableexcludes=kubernetes
                • sudo systemctl enable --now kubelet
                Bước 4. Cài pod-network
                • sudo kubeadm init --apiserver-advertise-address  10.0.0.78 --pod-network-cidr=10.244.0.0/16
                • mkdir -p $HOME/.kube
                • sudo cp -i /etc/kubernetes/admin.conf $HOME/.kube/config
                • sudo chown $(id -u):$(id -g) $HOME/.kube/config
                • kubectl apply -f https://raw.githubusercontent.com/.../kube-flannel.yml
                Phần II. Cài đặt cho máy làm Worker Node
                Máy Worker Node phải thông mạng với Master Node
                Bước 1
                • Thực hiện các lệnh từ 1 đến 3 ở phần I 
                Bước 2: Lấy token từ Master Node 
                • Sau đó quay lại console của Master Node thực hiện lệnh sau để lấy token :
                - sudo kubeadm token create --print-join-command
                - Kết quả in ra màn hình: kubeadm join 10.0.0.18:6443 --token k7psv3.k2l9ustrtu3z9iyh --discovery-token-ca-cert-hash sha256:xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                Bước 3: Join Worker Node vào Master Node 
                • Quay lại mành hình console Worker Node chạy lệnh vừa hiện trên Master Node: 
                - sudo kubeadm join 10.0.0.18:6443 --token k7psv3.k2l9ustrtu3z9iyh --discovery-token-ca-cert-hash sha256:xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                Bước 4: Xem Worker Node đã join vào Master Node 
                • kubectl get nodes
                Phần III : Deploy ứng dụng hello-app của google
                Bước 1: Tạo file yml
                • Vào console của Master Node tạo file hello-app.yml
                - vi hello-app.yml
                Nội dung file hello-app.yml
                apiVersion: apps/v1
                kind: Deployment
                metadata:
                  name: hello-app
                spec:
                  replicas: 5
                  selector:
                    matchLabels:
                      app: hello-app
                  template:
                    metadata:
                      labels:
                        app: hello-app
                 spec:
                      containers:
                      - image: gcr.io/google-samples/hello-app:1.0
                        imagePullPolicy: Always
                        name: hello-app
                        ports:
                        - containerPort: 8080
                ---
                apiVersion: v1
                kind: Service
                metadata:
                  name: hello-app
                spec:
                  type: NodePort
                  ports:
                    - port: 8080
                      targetPort: 8080
                      nodePort: 30001
                      protocol: TCP
                  selector:
                    app: hello-app
                Bước 2: Thực hiện deploy ứng dụng
                • kubectl create -f hello-app.yml
                - deploy lại dùng lệnh
                • kubectl apply -f hello-app.yml
                Bước 3: Xem và kiểm tra ứng dụng được deploy
                • kubectl get pods -o wide
                • kubectl get svc -o wide
                • [ec2-user@ip-10-0-0-18 ~]$  kubectl get svc
                • NAME         TYPE        CLUSTER-IP      EXTERNAL-IP   PORT(S)          AGE
                • hello-app    NodePort    10.96.188.113   none        8080:30001/TCP   19m
                • kubernetes   ClusterIP   10.96.0.1       none       443/TCP          23m
                • [ec2-user@ip-10-0-0-18 ~]$ curl  http://10.96.188.113:8080
                • Hello, world!
                • Version: 1.0.0
                • Hostname: hello-app-754bd794db-xhn2c
                Phần IV : Gỡ bỏ 
                • Thực hiện trên tất cả các node
                • sudo su
                • curl -sL https://git.io/scue-k8s-remove.sh | sh
                https://www.youtube.com/watch?v=OMBV4VZq060
            
               
            </h3>
            </div>
          
       </div>   
    )
};

export default Kubernetes;