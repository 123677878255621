
import Layout from '../views/Layout';
import "./RegisterDeveloper.css";
import React, { useEffect, useState, getInitialState } from "react";
import {    useRef } from "react";
 import { getDatabase, ref, onValue , child, push, update,set } from "firebase/database";

import {  onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";


import { useAuthState } from "react-firebase-hooks/auth";


import {db, auth,  signInWithGoogle, event_dashboard, load_toolbar ,getCurrentTime } from "../cfgfirebase";




  let style_css = {

  "margin-left":  '0px' ,
  "width": "650px",
  "border": "1px solid black"
  
  // ...
}

 let uid =''
  function handleSubmit(e) {
    // Prevent the browser from reloading the page
    e.preventDefault();
    // Read the form data
    const form = e.target;
    const formData = new FormData(form);
    // Or you can work with it as a plain object:
    const formJson = Object.fromEntries(formData.entries());
    console.log(formJson);
    formJson.user_id = uid
    formJson.event = 'event_register_developer_' + getCurrentTime()
    formJson.application = 'development'
        const post_data =    {
          "application": "resshare_development",
          "dataCollection": "../register_developer",
          "jsonmap": formJson,
          "user_id" :  uid
          }
         console.log(post_data);
        const postsRef= ref(db, 'request')
        // Get a key for a new Post.
        const newPostKey = push(child(ref(db), 'request')).key;
        const starCountRef = ref(db, 'request/' + newPostKey  );
        let bInssert = false
        if(!bInssert)
        {
         // alert(bInssert) 
            set(starCountRef, post_data)
            .then(() => {
            //    alert("Data saved successfully!") 
              // Data saved successfully!
            })
            .catch((error) => {
              // The write failed...
            });
            bInssert = true
            document.getElementById("full_name").value = '';
            document.getElementById("mail_address").value = '';

        const commentsRef = ref(db, "response/" + uid+  '/' + formJson.event );



      onChildAdded(commentsRef, (data) => {


           let message =   data.val().data
          alert(message)







      });
        }
  }
const RegisterDeveloper = () => {
           const [user, loading, error] = useAuthState(auth);
    useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
       return;
    }
    if (!user) 
    {
    }
    else
    {
           uid = user.uid;
    }
  }, [user, loading]);
  return (
        <Layout>
            <div class="form-style-8">
              <h2>Register to your account for developer</h2>
              <form method="post" onSubmit={handleSubmit}>
                <input type="text" name="full_name" id="full_name" placeholder="Full Name" />
                <input type="email" name="mail_address"  id="mail_address" placeholder="Email" />


                <input type="submit"   value="Register" />
              </form>
            </div>
        </Layout>
  );
}
export default RegisterDeveloper;