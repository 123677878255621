import './ReactCarousel.css';

import Layout from '../views/Layout';
import "./RegisterDeveloper.css";
import React, { useEffect, useState, getInitialState } from "react";
import {    useRef } from "react";
 import { getDatabase, ref, onValue , child, push, update,set } from "firebase/database";

import {  onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";


import { useAuthState } from "react-firebase-hooks/auth";


import {db, auth,  signInWithGoogle, event_dashboard, load_toolbar ,getCurrentTime } from "../cfgfirebase";

const ReactCarousel = () => {

  return (


          <Layout>
              <div class="form-style-8">
                <h2>Register to your account for developer</h2>





                        <div class="wrapper">
                          <section>
                            <h2>
                              Top Users
                            </h2>
                            <div class="images-list">
                                <div>
                                <img src="https://randomuser.me/api/portraits/men/1.jpg" alt=""/>
                                 <input type="submit"   value="Register" />
                                </div>
                              <img src="https://randomuser.me/api/portraits/women/12.jpg" alt=""/>
                              <img src="https://randomuser.me/api/portraits/men/3.jpg" alt=""/>
                              <img src="https://randomuser.me/api/portraits/women/13.jpg" alt=""/>
                              <img src="https://randomuser.me/api/portraits/men/1.jpg" alt=""/>
                              <img src="https://randomuser.me/api/portraits/women/12.jpg" alt=""/>
                              <img src="https://randomuser.me/api/portraits/men/3.jpg" alt=""/>
                              <img src="https://randomuser.me/api/portraits/women/13.jpg" alt=""/>
                              <img src="https://randomuser.me/api/portraits/men/1.jpg" alt=""/>
                              <img src="https://randomuser.me/api/portraits/women/12.jpg" alt=""/>
                              <img src="https://randomuser.me/api/portraits/men/3.jpg" alt=""/>
                              <img src="https://randomuser.me/api/portraits/women/13.jpg" alt=""/>
                                    <img src="https://randomuser.me/api/portraits/men/1.jpg" alt=""/>
                                    <img src="https://randomuser.me/api/portraits/women/12.jpg" alt=""/>
                                    <img src="https://randomuser.me/api/portraits/men/3.jpg" alt=""/>
                                    <img src="https://randomuser.me/api/portraits/women/13.jpg" alt=""/>
                                    <img src="https://randomuser.me/api/portraits/men/1.jpg" alt=""/>
                                    <img src="https://randomuser.me/api/portraits/women/12.jpg" alt=""/>
                                    <img src="https://randomuser.me/api/portraits/men/3.jpg" alt=""/>
                                    <img src="https://randomuser.me/api/portraits/women/13.jpg" alt=""/>
                                    <img src="https://randomuser.me/api/portraits/men/1.jpg" alt=""/>
                                    <img src="https://randomuser.me/api/portraits/women/12.jpg" alt=""/>
                                    <img src="https://randomuser.me/api/portraits/men/3.jpg" alt=""/>
                                    <img src="https://randomuser.me/api/portraits/women/13.jpg" alt=""/>
                            </div>
                          </section>
                        </div>

              </div>
          </Layout>









  );
}
export default ReactCarousel;
