import React from 'react'; 
// import ReactDOM from 'react-dom';

//  let styleip = { height: 500, width:600,color: 'blue'  }
//let labelTxt = 'Name (4 to 8 characters)'
let id =  'id'
let field_name =  ''

    function isEmptyObject(value) {
    
         
    if (value  === 'null') { return true;}
     if (value  === 'undefined') { return true;}
       if (value  === undefined) { return true;}
       
     
       
    
 
    
  return Object.keys(value).length === 0 && value.constructor === Object;
}

let style_css_json =''
          function  applyproperties(arr_properties) {
              
            
         let sentence =  isEmptyObject(arr_properties)
                  if (sentence  === false) { 
                    console.log(arr_properties ) ; 
                    
                    let fLen = arr_properties.length;
                
                 
                        for (let i = 0; i < fLen; i++) {
                            
                             console.log( arr_properties[i])  ;
                         
                            const sentence12 =  isEmptyObject(arr_properties[i]) ;
                               console.log("sentence12" ) ;
                            
                            console.log(sentence12 ) ;
                          
                          
                          
                                      if (sentence12  === false)  { 
                                           
                                       
                                        
                                       let key = Object.keys(arr_properties[i])[0]
                                        
                                          let value = Object.values(arr_properties[i])[0]
                                          
                                           switch (key)
                                               {
                                                case 'id':
                                                  id  = value;
                                                  break
                                                  case 'field_name':
                                                  field_name  = value;
                                                  break 
                                                case 'style':
                                                  style_css_json  = value;
                                                  break
                                                default  :
                                                 console.log(i+': '+ key+": "+value) ;
                                                  break
                                               }
                                        // console.log( value) ;
                                    }
                         
                        }
                   
                  }
          
          }
                        //       inputFieldsFirebase: inputFieldsFirebase,
                        // setInputFieldsFirebase: setInputFieldsFirebase
                        
                        
 


 
 
export default function Spinner({propsChild}) {
    
    
      style_css_json =''
 
 
  const inputs = propsChild.context.inputs
  
    const setInputs =  propsChild.context.setInputs
       
   

    const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    
    setInputs(values => ({...values, [name]: value}))
    }
    
    
    
    
   let  properties = propsChild.properties
   let context = propsChild.context
    field_name =  ''
    style_css_json =''
       applyproperties(properties);
       
       let  list_field  = context.list_field
           
   let  style_css  =      { width: '104px',height:  '35px'  }
         if( style_css_json != '')
         
           {style_css= style_css_json}
    // list_field.current.push(field_name);
 
        
   // console.log( inputFieldsFirebase)
      
        
     // let indexField = context.inputFieldsFirebase.length
      
       // addFields(context);
      
      
      
     
 


 
 
  
 // value={input.age}      
  return (
        <div  >
            <input className="main"  type="text" id={field_name}  name={field_name}  style={style_css}  
                onChange={handleChange}
            />
        </div>
         );
}



































// import React from 'react'; 
// // import ReactDOM from 'react-dom';
// import {    useRef } from "react";

// import { useState } from 'react';
// import ReactDOM from 'react-dom/client';

    
// export default function AppEditTextV1({propsChild}) {
     
//   const username = "username"
 
// const  inputsusername =""
 
//   const inputs = propsChild.inputs
  
//     const setInputs =  propsChild.setInputs
       
   

//     const handleChange = (event) => {
//     const name = event.target.name;
//     const value = event.target.value;
    
//     setInputs(values => ({...values, [name]: value}))
//     }


 

//   return (
//   <div  >
//       <label>Enter your name:
//       <input 
//         type="text" 
//         name="username" 
//         value={   inputs["username"]     || ""} 
//         onChange={handleChange}
//       />
//       </label>
//       <label>Enter your age:
//         <input 
//           type="number" 
//           name="age" 
//           value={inputs["age"] || ""} 
//           onChange={handleChange}
//         />
//       </label>
//   </div>
//   );
    
    
    
   
// }
