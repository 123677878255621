import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import "./RecyclerView.css";
import LinearLayout from '../components/LinearLayout';
import LinearLayoutContext from '../components/LinearLayoutContext';
import { Outlet    } from "react-router-dom";
import Layout from '../views/Layout';
import Navigation from '../components/Navigation';
import Searchbar from '../components/Searchbar';
import Application from "../pages/Application";
import Blogs from "../pages/Blogs";
import Contact from "../pages/Contact";
import NoPage from "../pages/NoPage";
import LeftMenuAntd from '../components/LeftMenuAntd';
import Right from '../components/Right';
import React, { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import {getCurrentTime, db, auth,  signInWithGoogle, event_dashboard, load_toolbar } from "../cfgfirebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import { getDatabase, ref, child, push, update,set } from "firebase/database";
import {   onValue   } from "firebase/database";
import {  onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import {    useRef } from "react";




import shortid from 'shortid';

import LearnReactView from '../views/LearnReactView';
import ReactView from '../views/ReactView';
import JavaScriptView from '../views/JavaScriptView';
import NullView from '../views/NullView';
import TextView from './TextView';
import ImageView from './ImageView';
import EditText from './EditText';
import GridDashboard from './GridDashboard';
import LinearHorizontalLayout from './LinearHorizontalLayout';
import GridView from './GridView';

import RelativeLayout from './RelativeLayout';
import LinearLayoutRecycle from './LinearLayoutRecycle';






 












// import {  useNavigate } from "react-router-dom";











//   let context = {
//     application: application,
//     app_user_id: app_user_id,
//     inputs: inputs,
//     setInputs: setInputs,  
//     comment: "comment12",
//     user_id: uid,
//      event: event,
//     list_field: list_field,
//     event_recycler_view: event_recycler_view,
//     event_recycler_view_child: event_recycler_view_child,
//     data_recycler_view: data_recycler_view
    

//   }



const RecyclerView = ({ props }) => {
    const data_recycler_view = props.context.data_recycler_view
      
    
    const subredditElementList = data_recycler_view.current.map(message => {
    
    
    
   // let message_layout = data.val().data.layout  
   // console.log("message_layout")
//console.log(message_layout)
    
    
    
    
    
    
    
    let subreddit = message.data.data.layout
    let data_field_value1 = message.data.data.script_param.book_data

   
    if(subreddit  === undefined)
    {}
    else
    {
        
         let context = message.context
// if (context.data_field_value ===  undefined)
//  {
//      context.data_field_value =  {};
//  }
    let data_field_value =  {};
 
    
            Object.keys(data_field_value1).forEach(key => {
            const   value = data_field_value1[key]
             
             data_field_value[key]= value 
            
 
            });
  
    
  
        
 //   context.data_field_value = data_field_value
    console.log("RecyclerView   context: ", context)
    
    let widget = subreddit.widget
   
     let propsLinearLayout = {
         properties: subreddit.properties,
         views: subreddit.views,
         context: context,
         grid_view_layout_item: message.data.data.script_param.grid_view_layout_item,
         recyclerview_id: message.data.id,
          data_field_value:data_field_value
         
        
      }  
             let propsChild = {
         action: subreddit.action,
         widget: subreddit.widget,
         properties: subreddit.properties,
         views: subreddit.views,
         context: context,
         data_field_value:data_field_value
        
      } 
                        //       inputFieldsFirebase: inputFieldsFirebase,
                        // setInputFieldsFirebase: setInputFieldsFirebase
    switch (widget) {
      case 'reactjs':
        return <ReactView key={shortid.generate()} />;
      case 'learnreactjs':
        return <LearnReactView key={shortid.generate()} />;
      case 'javascript':
        return <JavaScriptView key={shortid.generate()} />;
         
     case 'android.widget.TextView':
        return <TextView   propsChild={propsChild} />;
    case 'TextView':
        return <TextView   propsChild={propsChild} />;
        
        
         case 'android.widget.EditText':
        return <EditText   propsChild={propsChild} />;
    case 'EditText':
        return <EditText   propsChild={propsChild} />;


            
       
      case "android.widget.ImageView":
      return <ImageView   props={propsChild } />;
      
      case 'ImageView':
      return <ImageView   props={propsChild } />;
      
      
      

       
      case 'android.widget.LinearLayout':
         
       
      return <LinearLayoutRecycle   props={propsLinearLayout}   />;
     case 'LinearLayout':
       
       
        
      return <LinearLayoutRecycle   props={propsLinearLayout}  />;
    
      
      case 'NestedScrollView':
      return <LinearLayout   props={propsLinearLayout}  />;
      
      case 'androidx.core.widget.NestedScrollView':
      return <LinearLayout   props={propsLinearLayout} />;
      

      
      case 'androidx.cardview.widget.CardView':
      return <LinearLayout   props={propsLinearLayout} />;
            
      case 'CardView':
      return <LinearLayout  props={propsLinearLayout} />;
      
      
      case 'android.widget.RelativeLayout':
      return <RelativeLayout  props={propsLinearLayout}/>;
            
      case 'RelativeLayout':
      return <RelativeLayout   props={propsLinearLayout} />;    
      
     
    //   case 'RecyclerView':
    //   return <RecyclerView   views={propsLinearLayout.context.data_recycler_view} />;   
      
    //   case 'androidx.recyclerview.widget.RecyclerView':
    //   return <RecyclerView   views={propsLinearLayout.context.data_recycler_view} />;   
      

           
      case 'androidx.appcompat.widget.Toolbar':
      return <LinearLayout   props={propsLinearLayout} />;
            
      case 'Toolbar':
      return <LinearLayout   props={propsLinearLayout} />; 
      
      
      case 'android.widget.LinearHorizontalLayout':
      return <LinearLayout   props={propsLinearLayout} />; 
            
      case 'LinearHorizontalLayout':
      return <LinearLayout   props={propsLinearLayout} />; 
      
      
 
      
      
      
      
      
      case 'com.google.android.material.appbar.AppBarLayout':
      return <LinearLayout   props={propsLinearLayout}  />;
            
      case 'AppBarLayout':
      return <LinearLayout   props={propsLinearLayout} />;   
      
      case 'GridDashboard':
     return <LinearLayout   props={propsLinearLayout} />; 
       case 'android.widget.GridDashboard':
       return <LinearLayout   props={propsLinearLayout} />; 
      
      
      
     case 'GridView':
      let propsGridView0 = {
         metadata: subreddit,
         context: context  
        
      }  
      return <GridView   props={propsGridView0}      />;   
       case 'android.widget.GridView':
          let propsGridView1 = {
         metadata: subreddit,
         context: context  
        
      }  
      return <GridView   props={propsGridView1}    />;
      
      
      
      
 

       
      default:
        return (
          <NullView
            key={shortid.generate()}
          >{`Dang phat trien`}</NullView>
        );
    }
  }});

  let style_css_test = {


    "background":  '#F8F8F8',

   // "margin-bottom":  '50px',
   // 'border': '1px solid #257C9E',

  }

  return <div className="container"   >{subredditElementList}</div>;

//    return (
//                            <div class="wrapperRecyclerView"  >
//                              <sectionRecyclerView style={style_css_test}>
//                                <div class="images-listRecyclerView"  >
//                                    {subredditElementList}
//                                </div>
//                              </sectionRecyclerView>
//                            </div>
//  ) ;
}
export default RecyclerView;