


import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from "react";

import { useAuthState } from "react-firebase-hooks/auth";
let labelTxt = 'Name (4 to 8 characters)'
let id =  'id'



    function isEmptyObject(value) {


    if (value  === 'null') { return true;}
     if (value  === 'undefined') { return true;}
       if (value  === undefined) { return true;}



  return Object.keys(value).length === 0 && value.constructor === Object;
}
 let  textarea =''
  let  layout_width =''
  let  layout_height =''
  let  field_name =''



  function  applyproperties(arr_properties) {
      labelTxt  = ""

 textarea =''
 let sentence =  isEmptyObject(arr_properties)
  if (sentence  === false) {

    let fLen = arr_properties.length;


for (let i = 0; i < fLen; i++)
{
var some_variable =arr_properties[i]

if ( typeof(some_variable) !== "undefined" && some_variable !== null ) {

   let key = Object.keys(arr_properties[i])[0]
{

   let key = Object.keys(arr_properties[i])[0]

      let value = Object.values(arr_properties[i])[0]

       switch (key)
       {

        case 'field_name':
          field_name  = value;
          break
        case 'id':
          id  = value;
          break
        case 'text':
          labelTxt  = value;
          break
        case 'textarea':
          textarea  = value;
          break
          case 'style':
          style_css_json  = value;
          break
        case 'layout_width':
          layout_width  = value;
          break
        case 'layout_height':
          layout_height  = value;
          break
        default  :

          break
       }
    // console.log( value) ;

}
}

}

  }
  }
   let style_css_json = ''
    let style_css_design = {

                   "width":  '100px',

                   "height":  '50px',

                   "color":  '#8677ff',
                   "background": '#fff',


                    }
export default function TextViewDesign({ propsChild  }) {
 let navigate = useNavigate();

  const shoot = (props) => {


  const jsonString = JSON.stringify(props);

localStorage.setItem ('props', jsonString);
        let newString = jsonString.replaceAll("/", "%2F") ;




 navigate('/jsoneditor/'+ newString);

       if ((props.action != '' ) && ( props.action  != undefined ))
       {
          let  command =  props.action.command
           if(command == 'back')
           {
                navigate('/');
           }
             if(command == 'post')
           {
             const  post_conlection =  props.action.post_conlection




                 let  list_field  = props.context.list_field



             var data = {};

    // Using loop to insert key
    // value in Object

                console.log("list_field" )
                 console.log( list_field)

               list_field.forEach (function(field_name) {
console.log(field_name )
                var valueObj = document.getElementById(field_name);
                console.log(valueObj )
                if(valueObj != null)
                {

                     var value = valueObj.value;
                       console.log(value )
                     data[field_name] = value;
                  }


                })



        const   json =
          {
               "event_dashboard_current_day" : "event_dashboard_current_day_2023_06_18",
               "user_id" : props.context.app_user_id,
               "message_key" : "",
               "data" : data,

                "event_dashboard_sesion" : event_dashboard,
                "application" : props.context.application,
                "event" : props.context.event,
                "module" : "ad",
                "event_dashboard" : event_dashboard,
                "event_recycler_view" : "",
                "event_recycler_view_child" : ""

          }
             const myJSON = JSON.stringify(json);

        const   post_data =  {
                                "application": props.context.application,
                                "dataCollection": post_conlection,
                                "json": myJSON,
                                "key": "key1",
                                "module": "core",
                                "os": "web",
                                "user_id": props.context.user_id
                            }





                  const postsRef= ref(db, 'request')
        // Get a key for a new Post.
        const newPostKey = push(child(ref(db), 'request')).key;
        const starCountRef = ref(db, 'request/' + newPostKey  );
        let bInssert = false
        if(!bInssert)
        {
         // alert(bInssert)
            set(starCountRef, post_data)
            .then(() => {
            //    alert("Data saved successfully!")
              // Data saved successfully!
            })
            .catch((error) => {
              // The write failed...
            });

            bInssert = true
        }
                // Read the form data

                // const myJSON = JSON.stringify(inputs);
                //   console.log('myJSON');
                // console.log(myJSON);

           }
       }
       //   alert("generalform")

  };






   let  properties = propsChild.properties
   let  context = propsChild.context
     let  layout_context =  propsChild.layout_context

        let style_css = {



  "height":  '45px'
  // ...
}

        let style_css_0 = {

  "width":  '100%',

  "height":  '0px',
  "color": '#1677ff',
  "background": '#fff',
  "visibility": 'hidden'
  // ...
}

       labelTxt  = ""
       textarea =''
       style_css_json = ''
       field_name = ''

       applyproperties(properties);


            if(!(field_name === ''))
        {
             if(!(propsChild.data_field_value === undefined))
        {
            labelTxt = propsChild.data_field_value[field_name]

        }

        }


         if(style_css_json  !=  '')
        {
            style_css   =  style_css_json
        }

          if(layout_width  ===  '0dp')
        {
            style_css   =  style_css_0
        }

                 if(layout_height  ===  '0dp')
        {
            style_css   =  style_css_0
        }




       {
          let labelView =   <label      onClick={() => shoot(propsChild)}        style={style_css_design}  >{id} {labelTxt} </label>

               if(!(field_name === ''))
                  {
                       if(!(propsChild.data_field_value === undefined))
                  {
                     //  propsChild.layout_context.list_field_control[field_name] =  labelView;



                  }

                  }

  return (      labelView

    //<div      style={style_css}   >

  )

       }

  
}
