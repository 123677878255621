import "./RecyclerViewChild.css";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import LinearLayout from '../components/LinearLayout';
import LinearLayoutContext from '../components/LinearLayoutContext';


import { Outlet    } from "react-router-dom";
import Layout from '../views/Layout';
import Navigation from '../components/Navigation';
import Searchbar from '../components/Searchbar';
import Application from "../pages/Application";
import Blogs from "../pages/Blogs";
import Contact from "../pages/Contact";
import NoPage from "../pages/NoPage";
import LeftMenuAntd from '../components/LeftMenuAntd';
import Right from '../components/Right';
import React, { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import {getCurrentTime, db, auth,  signInWithGoogle, event_dashboard, load_toolbar } from "../cfgfirebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import { getDatabase, ref, child, push, update,set } from "firebase/database";
import {   onValue   } from "firebase/database";
import {  onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import {    useRef } from "react";




import shortid from 'shortid';

import LearnReactView from '../views/LearnReactView';
import ReactView from '../views/ReactView';
import JavaScriptView from '../views/JavaScriptView';
import NullView from '../views/NullView';
import TextView from './TextView';
import ImageView from './ImageView';
import EditText from './EditText';
import GridDashboard from './GridDashboard';
import LinearHorizontalLayout from './LinearHorizontalLayout';
import GridView from './GridView';

import RelativeLayout from './RelativeLayout';
import LinearLayoutRecycleChild from './LinearLayoutRecycleChild';


 import CircleImageView from './CircleImageView';

import "./Navigation.css";
const RecyclerViewChild = ({ props }) => {
    const data_recycler_view_child = props.context.data_recycler_view_child
 
    const subredditElementList = data_recycler_view_child.current.map(message => {
   // let message_layout = data.val().data.layout
   // console.log("message_layout")
//console.log(message_layout)
    let subreddit = props.grid_view_layout_item
    const recyclerview_id= props.recyclerview_id

        if(  message.data.id ===recyclerview_id )
        {
    let subreddit = props.grid_view_layout_item
    if(subreddit  === undefined)
    {}
    else
    {

            
      let data_field_value1 = message.data.data.script_param.item_data
    
      console.log("RecyclerView Child   data_field_value: ", data_field_value1)
    
          let context = message.context
            if (props.data_field_value ===  undefined)
         {
             props.data_field_value =     {};
         }
        
         
            
            Object.keys(data_field_value1).forEach(key => {
            const   value = data_field_value1[key]
          
             props.data_field_value[key]= value 
            
 
            });
            
            
    
    
    
    
    
    


    
    let widget = subreddit.widget
   
     let propsLinearLayout = {
         properties: subreddit.properties,
         views: subreddit.views,
         context: context,
         data_field_value: props.data_field_value

         
        
      }  
             let propsChild = {
         action: subreddit.action,
         widget: subreddit.widget,
         properties: subreddit.properties,
         views: subreddit.views,
         context: context  ,
         data_field_value: props.data_field_value
        
      } 
                        //       inputFieldsFirebase: inputFieldsFirebase,
                        // setInputFieldsFirebase: setInputFieldsFirebase
    switch (widget) {

      
      
      
      case 'android.widget.LinearLayout':


      return <LinearLayoutRecycleChild   props={propsLinearLayout}   />;
     case 'LinearLayout':



      return <LinearLayoutRecycleChild   props={propsLinearLayout}  />;
       

      
      
      
 

       
      default:
        return (
          <NullView
            key={shortid.generate()}
          >{`Dang phat trien`}</NullView>
        );
    }
  }}});


 // return <div className="container">{subredditElementList}</div>;
  
    return (
                            <div class="wrapperRecyclerViewChild">
                              <section>
                                <div class="images-list1">
                                    {subredditElementList}
                                </div>
                              </section>
                            </div>
  ) ;
  
}
export default RecyclerViewChild;