import {
  AppstoreOutlined,
  ContainerOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuUnfoldOutlined, 
} from '@ant-design/icons';
import { Button, Menu } from 'antd';
import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';


const LeftMenuWrapper = styled.div`
  .ant-menu-item {
    display: flex;
  }
`;

function getItem(label, key,  icon, children, type, url) {
  return {
    key,
    icon,
    children,
    label,
    type,
    url
  };
}
 //<Route path="createapp" element={<CreateApp />} />
         // <Route path="register" element={<Register />} />
const itemsData = [
  getItem('Recent Application', '1', <DesktopOutlined />,null, null , '/'),
  getItem('Register Developer', '2' , <PieChartOutlined />, null, null , 'registerdeveloper'),
  getItem('Create Application', '3', <DesktopOutlined />,null, null , 'createappliation'),
  getItem('Create ReactCarousel', '3', <DesktopOutlined />,null, null , 'reactcarousel'),
  
  
 
 
 
];


import { onAuthStateChanged } from "../cfgfirebase";

const uid = onAuthStateChanged

const LeftMenuAntd = () => {
  let navigate = useNavigate();
  
  const [collapsed, setCollapsed] = useState(false);
  
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  
  const onClickMenu = (url) => {
    navigate(url);
  };

  
  return (
    
        
    <LeftMenuWrapper className="left-menu__wrapper">
    
     <div className="UserInfo-name">
         
      </div>
      <Button
        type="primary"
        onClick={toggleCollapsed}
        style={{ marginBottom: 16 }}
      >
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </Button>
      <Menu
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        theme="dark"
        inlineCollapsed={collapsed}
      >
        {itemsData.map((item) => (
          <Menu.Item key={item.key} onClick={() => onClickMenu(item.url)}><span>{item.icon}</span>{item.label} </Menu.Item>
        ))}
      </Menu>
    </LeftMenuWrapper>
  );
};

export default LeftMenuAntd;