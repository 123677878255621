import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";

 
 

const firebaseConfig = {
  apiKey: "AIzaSyAcbCW3pCnfBkl4T3P8nNWD2HBKf1lBaJw",
  authDomain: "resshare-request-cb5e2.firebaseapp.com",
  databaseURL: "https://resshare-request-cb5e2-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "resshare-request-cb5e2",
  storageBucket: "resshare-request-cb5e2.appspot.com",
  messagingSenderId: "675294390499",
  appId: "1:675294390499:web:ca545a81fa4d55824f3dd6",
  measurementId: "G-3QBCY5K405"
};


 

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();


import { getDatabase, ref, set } from "firebase/database";
function getCurrentTime(){
var now = new Date();
var now_date = now.getDate();
var now_month = now.getMonth(); now_month++;
 now_month = now_month < 10 ? '0'+now_month : now_month; 
var now_year = now.getFullYear();
var now_hours = now.getHours();
now_hours = now_hours < 10 ? '0'+now_hours : now_hours; 
var now_min = now.getMinutes();
now_min = now_min < 10 ? '0'+now_min : now_min;
var now_sec = now.getSeconds();
now_sec = now_sec < 10 ? '0'+now_sec : now_sec;
var voting_started = now_year + "_" + now_month + "_" + now_date + "_" + now_hours + "_" + now_min + "_" + now_sec; 
return voting_started;
}
const currentTime = getCurrentTime();
const event_dashboard = 'event_dashboard_'+ currentTime;
const load_toolbar = 'load_toolbar_'+ currentTime;
 
const db = getDatabase(app);


import {  onAuthStateChanged } from "firebase/auth";

 

let uid ="jj"
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
      uid = user?.uid;
      return uid
    // ...
  } else {
    // User is signed out
    // ...
    
  }
   return "Please Login"
});


 

function writeUserData(userId,displayName,email,photoURL ) {
  
  
  
  
  
   
   

  
  
  
 
  set(ref(db, 'request_firebase_users/' + userId), {
          user_id: userId,
          user_name: displayName,
          mail_address: email,
          avatar: photoURL,
          user_type: "google"
       
    
   
  });
}


const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
   //  alert("screen");
    
     const credential = GoogleAuthProvider.credentialFromResult(res);
    const token = credential.accessToken;
    // The signed-in user info.
    
    
    const user = res.user;
    
     
if (user !== null) {
  // The user object has basic properties such as display name, email, etc.
  const displayName = user.displayName;
  const email = user.email;
  const photoURL = user.photoURL;
  const emailVerified = user.emailVerified;

  // The user's ID, unique to the Firebase project. Do NOT use
  // this value to authenticate with your backend server, if
  // you have one. Use User.getToken() instead.
   const uid = user.uid;
   writeUserData(user.uid, displayName , email , photoURL  )
}




  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    // await addDoc(collection(db, "users"), {
    //   uid: user.uid,
    //   name,
    //   authProvider: "local",
    //   email,
    // });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logout = () => {
  signOut(auth);
};
 
 

export {
  event_dashboard,
  load_toolbar,
  auth,
  db,
  signOut,
  onAuthStateChanged,
 
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  getCurrentTime,
  
};