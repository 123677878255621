import React from 'react';
import shortid from 'shortid';

import LearnReactView from '../views/LearnReactView';
import ReactView from '../views/ReactView';
import JavaScriptView from '../views/JavaScriptView';
import NullView from '../views/NullView';
import TextView from './TextView';
import ImageView from './ImageView';
import EditText from './EditText';
import AppEditTextV1 from './AppEditTextV1';
import GridDashboard from './GridDashboard';
import LinearHorizontalLayout from './LinearHorizontalLayout';
import RelativeLayout from './RelativeLayout';
import LinearLayout from './LinearLayout';



import GridView from './GridView';



import RadioGroup from './RadioGroup';




 import RecyclerView from './RecyclerView';
 import StarRating from './StarRating';
 import RecyclerViewChild from './RecyclerViewChild'; 
 
  import CircleImageView from './CircleImageView';
 
 
 
 
 



 
 
 
  // const properties =   [
  //   {
  //     "layout_width": "300dp"
  //   },
  //   {
  //     "background": "@drawable/bg_white_selector"
  //   },
  //   {
  //     "id": "@+id/txtDescription"
  //   },
  //   {
  //     "layout_height": "45dp"
  //   },
  //   {
  //     "textSize": "16sp"
  //   },
  //   {
  //     "text": "Các chức năng ứng dụng trắc nghiệm quiz3"
  //   },
  //   {
  //     "ellipsize": "end"
  //   },
  //   {
  //     "maxLines": 2
  //   },
  //   {
  //     "textColor": "@color/browser_actions_text_color"
  //   },
  //   {
  //     "layout_height:": "30dp"
  //   }
  // ];
  
let labelTxt = 'Name (4 to 8 characters)'
let id =  'id'

    function isEmptyObject(value) {


    if (value  === 'null') { return true;}
     if (value  === 'undefined') { return true;}
       if (value  === undefined) { return true;}
        if (value  === null) { return true;}
         if (value  === '') { return true;}



  return Object.keys(value).length === 0 && value.constructor === Object;
}


let  textarea =''
  function  applyproperties(arr_properties) {
 textarea =''
 let sentence =  isEmptyObject(arr_properties)
  if (sentence  === false) {

    let fLen = arr_properties.length;


for (let i = 0; i < fLen; i++) {
    if(arr_properties[i] != undefined)
    {
   let key = Object.keys(arr_properties[i])[0]

      let value = Object.values(arr_properties[i])[0]

       switch (key)
       {
        case 'id':
          id  = value;
          break
        case 'text':
          labelTxt  = value;
          break
        case 'textarea':
          textarea  = value;
          break
          case 'style':
          style_css_db  = value;
          break
        default  :

          break
       }
    // console.log( value) ;

}
}

  }
  }

    let style_css_db = ''
    let style_css = {

  "width":  '1064px',

  "height":  '50px',
  "background-color":  '#00BFFF'

  // ...
}
export default function LinearLayoutRecycleChild({ props }   ) {
      style_css_db = ''
  applyproperties(props.properties)
  if(  style_css_db === '' )
   {

   }

const map = {};

// insert key-value-pair
map['key1'] = 'value1';
map['key2'] = 'value2';
map['key3'] = 'value3';

// check if map contians key
if (map['key1']) {
    console.log('Map contains key1');
}

// get value with specific key
console.log(map['key1']);


  let views =  props.views
  let context =    props.context
   let list_field_control  =  {};
    let layout_context = {


       list_field_control: list_field_control
    }
layout_context.list_field_control["test"] = "tesst ";

 const plainObjMap =   layout_context.list_field_control;

alert(Object.keys(plainObjMap).length)




  
   console.log("LinearLayoutRecycle views props: ", props)
  const subredditElementList = views.map(subreddit => {
    let widget = subreddit.widget
      let propsLinearLayout = {
         properties: subreddit.properties,
         views: subreddit.views,
         context: context,
           layout_context: layout_context,
         grid_view_layout_item: props.grid_view_layout_item,
         recyclerview_id: props.recyclerview_id,
          data_field_value: props.data_field_value
        
      }  
       let propsChild = {
         action: subreddit.action,
         widget: subreddit.widget,
         properties: subreddit.properties,
         views: subreddit.views,
         context: context ,
         layout_context: layout_context,
         data_field_value: props.data_field_value
        
      }  
    switch (widget) {
   
      case 'RecyclerView':
    return <RecyclerViewChild   props={propsLinearLayout}   />;
      
      case 'androidx.recyclerview.widget.RecyclerView':
      return <RecyclerViewChild   props={propsLinearLayout}   />;
    
     case 'StarRatingView':
         
         
     return <StarRating value={0} />;
 
      
      case 'reactjs':
        return <ReactView key={shortid.generate()} />;
      case 'learnreactjs':
        return <LearnReactView key={shortid.generate()} />;
      case 'javascript':
        return <JavaScriptView key={shortid.generate()} />;
         
     case 'android.widget.TextView':
         return <TextView   propsChild={propsChild} />;
    case 'TextView':
        return <TextView   propsChild={propsChild} />;
        
        
         case 'android.widget.EditText':
        return <EditText   propsChild={propsChild} />;
    case 'EditText':
        return <EditText   propsChild={propsChild} />;
        
        
        
                 case 'android.widget.SearchView':
        return <SearchView   propsChild={propsChild} />;
    case 'SearchView':
        return <SearchView   propsChild={propsChild} />;
        
        
        
                         case 'android.widget.Spinner':
        return <Spinner   propsChild={propsChild} />;
    case 'Spinner':
        return <Spinner   propsChild={propsChild} />;
        


            
       
      case "android.widget.ImageView":
      return <ImageView   props={propsChild } />;
      
      case 'ImageView':
      return <ImageView   props={propsChild } />;
      
            case 'de.hdodenhof.circleimageview.CircleImageView':
            return <CircleImageView   props={propsChild} />;
      
      
            case "android.widget.RadioGroup":
      return <RadioGroup   props={propsChild } />;
      
      case 'RadioGroup':
      return <RadioGroup   props={propsChild } />;
      
      
      
      
      
      
      
      
      case 'android.widget.LinearLayout':
   
       
      return <LinearLayout   props={propsLinearLayout}   />;
     case 'LinearLayout':
       
      
        
      return <LinearLayout   props={propsLinearLayout}  />;
      
    
      
      case 'NestedScrollView':
      return <LinearLayout   props={propsLinearLayout}  />;
      
      case 'androidx.core.widget.NestedScrollView':
      return <LinearLayout   props={propsLinearLayout}  />;
      

      
      case 'androidx.cardview.widget.CardView':
      return <LinearLayout props={propsLinearLayout}  />;
            
      case 'CardView':
      return <LinearLayout  props={propsLinearLayout}  />;
      
      
      case 'android.widget.RelativeLayout':
      return <RelativeLayout  props={propsLinearLayout}  />;
            
      case 'RelativeLayout':
      return <RelativeLayout  props={propsLinearLayout}  />;     
           
      case 'androidx.appcompat.widget.Toolbar':
      return <LinearLayout   props={propsLinearLayout}  />;
            
      case 'Toolbar':
      return <LinearLayout  props={propsLinearLayout}  />;
      
      
      case 'android.widget.LinearHorizontalLayout':
     return <LinearLayout  props={propsLinearLayout}  />;
            
      case 'LinearHorizontalLayout':
     return <LinearLayout  props={propsLinearLayout}  />;
      
      
 
      
      
      
      
      
      case 'com.google.android.material.appbar.AppBarLayout':
      return <LinearLayout   props={propsLinearLayout}  />;
            
      case 'AppBarLayout':
      return <LinearLayout   props={propsLinearLayout}  />;  
      
      case 'GridDashboard':
     return <LinearLayout  props={propsLinearLayout}  />;
       case 'android.widget.GridDashboard':
   return <LinearLayout  props={propsLinearLayout}  />;
      
      
      
      case 'GridView':
      let propsGridView0 = {
         metadata: subreddit,
         context: context  
        
      }  
      return <GridView   props={propsGridView0}      />;   
       case 'android.widget.GridView':
          let propsGridView1 = {
         metadata: subreddit,
         context: context  
        
      }  
      return <GridView   props={propsGridView1}    />;
      
      
       
      
 

      default:
        return (
          <NullView
            key={shortid.generate()}
          >{`Dang phat trien`}</NullView>
        );
    }
  });


  let style_css_test = {


    "background":  '#F8F8F8',




  }
  // "margin-bottom":  '50px',
    //'border': '1px solid #257C9E',
  return <div className="container"   >{subredditElementList}</div>;


//  if(  style_css_db === '' )
//   {
//        return <div className="container"    > {subredditElementList}       </div>;
//   }
//   else
//  {
//      return <div className="container"  style={style_css_db}   > {subredditElementList}       </div>;
//  }

}
