import { SearchOutlined }from '@ant-design/icons';

import "./Searchbar.css";



  import {grid_main_data} from '../pages/LayoutMain';
 
 
 import { useNavigate } from 'react-router-dom';

import { getDatabase, ref, onValue , child, push, update,set } from "firebase/database";

import {  onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";



 
import {db, auth,  signInWithGoogle, event_dashboard, load_toolbar } from "../cfgfirebase";

import React, { useEffect, useState, getInitialState } from "react";
import {    useRef } from "react";
 










 
import { useAuthState } from "react-firebase-hooks/auth";
import ReactDOM from "react-dom/client";
 

  
 
 const shoot = (props) => 
 {
   
 
     var value_search = document.getElementById("search").value;

   console.log("props")
   console.log(props)
   

      const   json =     { 
                  "query" : value_search,
                  "event" : event_dashboard,
                   "event_dashboard" : event_dashboard,
                   "user_id" :uid
                   }
    
        // const   json =  
        //   {
        //       "event_dashboard_current_day" : "event_dashboard_current_day_2023_06_18",
        //       "user_id" : props.context.app_user_id,
        //       "message_key" : "",
        //       "data" : data,
          
        //         "event_dashboard_sesion" : "event_dashboard_sesion_ios_2023_06_18_09_03_14_083",
        //         "application" : props.context.application,
        //         "event" : props.context.event,
        //         "module" : "ad",
        //         "event_recycler_view" : "",
        //         "event_recycler_view_child" : ""
              
        //   }  
             const myJSON = JSON.stringify(json);
             
             
                //   {
//   "application": "resshare_configuration",
//   "dataCollection": "draft/core/seach_app",
    //   "json": "{\n 
                  //\"query\" : \"Áa\",\n 
                  //\"event\" : \"event_dashboard\",\n 
                  //\"event_dashboard\" : \"event_dashboard\",\n
                  //\"user_id\" : \"0s1gRycDo2hw6InZH1uAaLqkZva2\"
    //\n}",
//   "key": "key1",
//   "module": "configuration",
//   "os": "IOS",
//   "user_id": "0s1gRycDo2hw6InZH1uAaLqkZva2"
// }
            
        const   post_data =  {
                                "application": "resshare_configuration",
                                "dataCollection": "draft/core/seach_app",
                                "json": myJSON,
                                "key": "key1",
                                "module": "configuration",
                                "os": "web",
                                  "user_id" :  uid
                            }
    
            
            
            
            
                  const postsRef= ref(db, 'request')
        // Get a key for a new Post.
        const newPostKey = push(child(ref(db), 'request')).key;
        const starCountRef = ref(db, 'request/' + newPostKey  );
        let bInssert = false
        if(!bInssert)
        {
         // alert(bInssert) 
            set(starCountRef, post_data)
            .then(() => {
            //    alert("Data saved successfully!") 
              // Data saved successfully!
            })
            .catch((error) => {
              // The write failed...
            });
          
            bInssert = true
        }
 
            
    
 

   
   
   
   

   
 }
  let  uid = ''
export default function Searchbar({props}) {
   const [user, loading, error] = useAuthState(auth);
  
    useEffect(() => {
      
    if (loading) {
      // maybe trigger a loading screen
       return;
    }
    if (!user) 
    {
       
    }
    else
    {
           uid = user.uid;
    }
    
    
  }, [user, loading]);
  
  
  return (
    <div class="seach_main">
      <div class="search-container">
        <input type="text" placeholder="Search.." id="search"/>
        <span className='search-icon'><SearchOutlined   onClick={() => shoot(props)}     /></span>
      </div>
    </div>
  );
}



 