import React, { useState } from "react";
import { JsonPrettierTextarea } from "json-pretty-textarea";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
  useSearchParams,
} from "react-router-dom";
import "./JsonEditor.css";
import ImageView from '../components/ImageView';
//import JSONPretty from 'react-json-pretty';
//import 'react-json-pretty/themes/monikai.css';
//import JsonFormatter from 'react-json-formatter'

const books= [{
    "author_name": "Tác giả: B",
    "book_id": "BK-NvLW2RuuKGmJ6XLpHQv",
    "book_items_id": "IT-NvMsN2aWArmsRdp5uu1",
    "category_name": "Loại: C",
    "cover_price": "Giá bìa: 0",
    "free_loan": "Sách cho mượn",
    "icon": "https://firebasestorage.googleapis.com/v0/b/resshare-request-cb5e2.appspot.com/o/book%2F6BB23D04-F045-4579-B402-FE90E3AA3802?alt=media&token=c7625406-a128-49d0-94df-eb9ef09ea7ec",
    "name": "Tên sách: A",
    "quantity": "Số lượng: 1",
    "state_flow": "",
    "state_flow_title": "Xóa",
    "value_price": "Số ngày mượn: 30",
    "views_count": "Lượt xem: 2"
  },
  {
    "author_name": "Tác giả: B",
    "book_id": "BK-NvLW2RuuKGmJ6XLpHQv",
    "book_items_id": "IT-NvMsN2aWArmsRdp5uu1",
    "category_name": "Loại: C",
    "cover_price": "Giá bìa: 0",
    "free_loan": "Sách cho mượn",
    "icon": "https://firebasestorage.googleapis.com/v0/b/resshare-request-cb5e2.appspot.com/o/book%2F6BB23D04-F045-4579-B402-FE90E3AA3802?alt=media&token=c7625406-a128-49d0-94df-eb9ef09ea7ec",
    "name": "Tên sách: A",
    "quantity": "Số lượng: 1",
    "state_flow": "",
    "state_flow_title": "Xóa",
    "value_price": "Số ngày mượn: 30",
    "views_count": "Lượt xem: 2"
  },{
    "author_name": "Tác giả: B",
    "book_id": "BK-NvLW2RuuKGmJ6XLpHQv",
    "book_items_id": "IT-NvMsN2aWArmsRdp5uu1",
    "category_name": "Loại: C",
    "cover_price": "Giá bìa: 0",
    "free_loan": "Sách cho mượn",
    "icon": "https://firebasestorage.googleapis.com/v0/b/resshare-request-cb5e2.appspot.com/o/book%2F6BB23D04-F045-4579-B402-FE90E3AA3802?alt=media&token=c7625406-a128-49d0-94df-eb9ef09ea7ec",
    "name": "Tên sách: A",
    "quantity": "Số lượng: 1",
    "state_flow": "",
    "state_flow_title": "Xóa",
    "value_price": "Số ngày mượn: 30",
    "views_count": "Lượt xem: 2"
  }];

  const categories = [
    {"name":"Sách của bạn"},
    {"name":"Sách cho mượn"},
    {"name":"Sách đi mượn"},
    {"name":"Sách mua"},

  ]

if (!library)
   var library = {};

library.json = {
   replacer: function(match, pIndent, pKey, pVal, pEnd) {
      var key = '<span class=json-key>';
      var val = '<span class=json-value>';
      var str = '<span class=json-string>';
      var r = pIndent || '';
      if (pKey)
         r = r + key + pKey.replace(/[": ]/g, '') + '</span>: ';
      if (pVal)
         r = r + (pVal[0] == '"' ? str : val) + pVal + '</span>';
      return r + (pEnd || '');
      },
   prettyPrint: function(obj) {
      var jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/mg;
      return JSON.stringify(obj, null, 3)
         .replace(/&/g, '&amp;').replace(/\\"/g, '&quot;')
         .replace(/</g, '&lt;').replace(/>/g, '&gt;')
         .replace(jsonLine, library.json.replacer);
      }
   };



const JsonEditor = () => {

//  const jsonStyle = {
//    propertyStyle: { color: 'red' },
//    stringStyle: { color: 'green' },
//    numberStyle: { color: 'darkorange' }
//  }
 const style_css = {

                    "height": "600px",
                    "width": "100%"
                  }
  const { data } = useParams();
//
//const jsonObject = JSON.parse(jsonString);
//    var parseJSON = JSON.parse(jsonString);
//         var JSONInPrettyFormat = JSON.stringify(parseJSON, undefined, 4);


 // const [searchParams, setSearchParams] = useSearchParams();
 // const { data1 } = searchParams.get("json")

    const [selected, setSelected] = useState('');

    var name = localStorage.getItem('props');

 console.log(name);
     const jsonString = JSON.stringify(name);
     const jsonData = JSON.stringify(name, null, 2);

let obj = JSON.parse(name);

   var obj1={}
   obj1.properties  =obj.properties
   obj1.widget  =obj.widget
   obj1.views  =obj.views

 var  namename = library.json.prettyPrint( name )
 var pretty = JSON.stringify(obj1, undefined, 2);

     console.log(JSON.stringify(jsonString));
     const jsonStyle = {
         propertyStyle: { color: 'red' },
         stringStyle: { color: 'green' },
         numberStyle: { color: 'darkorange' }
       }


    return (
        <div  >


            <div className="page-content">
                <div className="categories-bookcase">
                {categories.map(cate => {
                    return (<div onClick={() => { setSelected(cate.name); alert("chọn " + cate.name); }} className={`category-item ${cate.name === selected ? 'selected' : ''}`}>
                        {cate.name}
                    </div>)
                })}

                </div>

                <div  >
                    <textarea
                        defaultValue = { pretty }
                        style={style_css}

                    />
                </div>

            </div>
        </div>
    );
  };
  
  export default JsonEditor;